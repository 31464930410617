import { applyAuthTokenInterceptor, setAuthTokens, clearAuthTokens } from 'axios-jwt';
import axios, { resolve } from 'axios';
import { Capacitor } from "@capacitor/core";
import { Preferences } from '@capacitor/preferences';
import { Playback } from 'playback';

// 1. Create an axios instance that you wish to apply the interceptor to
const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

// axiosInstance.interceptors.request.use((request) => {
//   console.log(JSON.stringify(request, null, 2));
//   return request;
// });
// axiosInstance.interceptors.response.use((response) => {
//   console.log(JSON.stringify(response, null, 2));
//   return response;
// });

axiosInstance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 422) {
      // Handle the validation error
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  }
);

// 2. Define token refresh function.
const requestRefresh = async (refresh) => {
  try {
    // Notice that this is the global axios instance, not the axiosInstance!  <-- important
    const response = await axios.post(`${BASE_URL}/auth/refresh`, { refresh });

    await Preferences.set({
      key: 'auth',
      value: JSON.stringify(response),
    });
    await Playback.setBearerToken({bearerToken: `Bearer ${response.data.authorization.token}`})

    setAuthTokens({
      accessToken: response.data.authorization.token,
      refreshToken: response.data.authorization.token,
    })

    return {
      accessToken: response.data.authorization.token,
      refreshToken: response.data.authorization.token,
    };
  } catch (err) {
    logout();
    window.location.reload();
    return;
  }
  
};

// 3. Apply interceptor
applyAuthTokenInterceptor(axiosInstance, {
  requestRefresh,
  header: 'Authorization', // header name
  headerPrefix: 'Bearer ', // header value
}); // Notice that this uses the axiosInstance instance.  <-- important

// 4. Logging in
export const login = async (params) => {
  let response = {};
  try {
    response = await axiosInstance.post('/auth/login', params);
    const platform = Capacitor.getPlatform();
    if (platform !== "web") {
      await Preferences.set({
        key: 'auth',
        value: JSON.stringify(response),
      });
      await Playback.setBearerToken({bearerToken: `Bearer ${response.data.authorization.token}`})
    }
  } catch (err) {
    console.log('err -->', err);
    if (err.response.status == 401) {
      throw new Error('Invalid credentials');
    } else {
      throw new Error('Something went wrong. Please try again later.');
    }
  }

  // save tokens to storage
  setAuthTokens({
    accessToken: response.data.authorization.token,
    refreshToken: response.data.authorization.token,
  });

  return response.data.user;
};

// 5. Logging out
export const logout = () => {
  clearAuthTokens()
  localStorage.clear()
};

export default axiosInstance;
// Now just make all requests using your axiosInstance instance
// axiosInstance.get('/api/endpoint/that/requires/login').then((response) => {});
