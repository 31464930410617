import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { actionSheetController } from '@ionic/vue';


function canTriggerListSelect(route) {
	return route.params?.list && route.params.list !== 'freeTitles';
}

function openListSelect(route, router) {
	if (canTriggerListSelect(route)) {
		presentActionSheet(route, router);
  } else {
    router.push({ name: 'list', params: { list: 'library' } });
  }
}

const presentActionSheet = async (route, router) => {
  const actionSheet = await actionSheetController.create({
    buttons: [
      {
        text: 'Library',
        data: { list: 'library' },
        cssClass: route?.params?.list === 'library' ? 'font-bold' : '',
      },
      {
        text: 'In Progress',
        data: { list: 'inProgress' },
        cssClass: route?.params?.list === 'inProgress' ? 'font-bold' : '',
      },
      {
        text: 'Not Started',
        data: { list: 'notStarted' },
        cssClass: route?.params?.list === 'notStarted' ? 'font-bold' : '',
      },
      {
        text: 'Purchases',
        data: { list: 'purchases' },
        cssClass: route?.params?.list === 'purchases' ? 'font-bold' : '',
      },
      {
        text: 'Downloads',
        data: { list: 'downloaded' },
        cssClass: route?.params?.list === 'downloaded' ? 'font-bold' : '',
      },
      {
        text: 'Archived',
        data: { list: 'archived' },
        cssClass: route?.params?.list === 'archived' ? 'font-bold' : '',
      },
      {
        text: 'Cancel',
        role: 'cancel',
        data: {
          action: 'cancel',
        },
      },
    ],
  });

  await actionSheet.present();

  const { data } = await actionSheet.onDidDismiss();
	if (data?.role === 'cancel') return;

  if (data?.list) {
    router.replace({ name: 'list', params: { list: data?.list } });
  }
};

export {
	openListSelect,
	canTriggerListSelect,
}