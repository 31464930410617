import { ref, computed } from 'vue';
import { formatLocalFromUTC } from '@/utils/date';
import humanize from 'humanize-duration';
import { DateTime } from 'luxon';

const now = ref(new Date());

setInterval(() => {
	now.value = new Date();
}, 1000);

const endsAtDiff = (promo) => computed(() => {
	let expireDateTime = DateTime.fromISO(promo?.ends_at_iso);
	let diff = expireDateTime.toMillis() - now.value.getTime();
	return isNaN(diff) ? null : diff;
});

const signupEndsAtDiff = (promo) => computed(() => {
	let expireDateTime = DateTime.fromISO(promo?.readers_end_at);
	let diff = expireDateTime.toMillis() - now.value.getTime();
	return isNaN(diff) ? null : diff;
});

var expiresByPromoId = {};

export const getExpiry = (promo) => {
	let promoId = promo?.id;
	if (promoId && !expiresByPromoId[promoId]) {
		const endsAtDiffComputed = endsAtDiff(promo);
		const signupEndsAtDiffComputed = signupEndsAtDiff(promo);
		const hasEnded = computed(() => endsAtDiffComputed.value < 0);
		const hasSignupEnded = computed(() => signupEndsAtDiffComputed.value < 0);
		const hasExpired = computed(() => hasEnded.value || (hasSignupEnded.value && !promo?.permissions?.canListen));
		const endsSoon = computed(() => endsAtDiffComputed.value && endsAtDiffComputed.value < 1000 * 60 * 60 * 24 * 60);
		const signupEndsSoon = computed(() => signupEndsAtDiffComputed.value && signupEndsAtDiffComputed.value < 1000 * 60 * 60 * 24 * 60);
		const willExpire = computed(() => !hasExpired.value && (endsSoon.value || signupEndsSoon.value));
		const expiresAt = computed(() => {
			if (hasEnded.value) {
				return `Expired ${humanize(endsAtDiffComputed.value, { largest: 1, round: true })} ago`;
			} else if (hasSignupEnded.value) {
				return `Expired ${humanize(signupEndsAtDiffComputed.value, { largest: 1, round: true })} ago`;
			} else if (willExpire.value) {
				if (endsSoon.value) {
					return `Expires in ${humanize(endsAtDiffComputed.value, { largest: 1, round: true })}`;
				} else if (signupEndsSoon.value) {
					return `Expires in ${humanize(signupEndsAtDiffComputed.value, { largest: 1, round: true })}`;
				}
			}
		});
		
		expiresByPromoId[promoId] = {
			endsAtDiff: endsAtDiffComputed,
			signupEndsAtDiff: signupEndsAtDiffComputed,
			hasEnded,
			hasSignupEnded,
			hasExpired,
			willExpire,
			expiresAt,
		};
	}
	return expiresByPromoId[promoId];
}
