import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { IonicVue } from '@ionic/vue';
import { isPlatform } from '@ionic/vue';
import { App as CapApp, URLOpenListenerEvent } from '@capacitor/app';
import { useAuthStore } from '@/stores/useAuthStore';
//@ts-ignore
import { useLibraryStore } from '@/stores/useLibraryStore';
//@ts-ignore
import FernandPlugin from '@/plugins/FernandPlugin';
import { isLoggedIn, setAuthTokens, getAccessToken } from 'axios-jwt';
import { Preferences } from '@capacitor/preferences';
// import eruda, { Eruda } from 'eruda';
// eruda.init()

// import { Playback } from 'playback';
var decode = function (input: string) {
  // Replace non-url compatible chars with base64 standard chars
  input = input
    .replace(/-/g, '+')
    .replace(/_/g, '/')
    .replace('%3D%3D', '==')

  // Pad out with standard base64 required padding characters
  var pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
    }
    input += new Array(5 - pad).join('=');
  }
  return input;
}
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
// import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Main app styles + Tailwind */
import './theme/app.css';

import { Capacitor } from "@capacitor/core";
import { CapacitorSQLite, SQLiteConnection } from "@capacitor-community/sqlite";
import {
  defineCustomElements as jeepSqlite,
  applyPolyfills,
} from "jeep-sqlite/loader";
import { Playback } from 'playback';

Playback.echo({ value: 'b' })


applyPolyfills().then(() => {
  jeepSqlite(window);
});

window.addEventListener("DOMContentLoaded", async () => {

  CapApp.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
    // alert(event.url)

    // Check if jwt is in query params
    const queryParams = extractQueryParams(event.url);
    let jwtTokenEncoded = queryParams?.jwt
    if (jwtTokenEncoded) {
      let decoded = decode(jwtTokenEncoded)
      let jwtToken = decoded
      try {
        jwtToken = atob(decoded)
        let data = {
          authorization: {
            token: jwtToken
          }
        }
        await Preferences.set({
          key: 'auth',
          value: JSON.stringify({ data }),
        });
      } catch (e) { }

      setAuthTokens({
        accessToken: jwtToken,
        refreshToken: jwtToken,
      })
    }

    if (event.url.indexOf('applink') !== -1) {
      // alert('applink: ' + event.url.split('/applink')[1])
      return router.push({ path: event.url.split('/applink')[1] });
    }

    const subdomain = event.url.split('://')[1].split('.')[0];
    if (subdomain === process.env.VUE_APP_APP_HOST.split('.')[0]) {
      // alert('library: ' + event.url.split('/#')[1])
      // This is the web version of the app, so use the path directly
      return router.push({ path: event.url.split('/#')[1] });
    }

    const splitter = event.url.indexOf(process.env.VUE_APP_APPSCHEME) === 0 ? process.env.VUE_APP_APPSCHEME : process.env.VUE_APP_WEB_HOST + '/';
    const parts = event.url.split(splitter);
    const path = parts[1];
    const uri = path.split('?')[0];
    const segments = uri.split('/');

    if (segments[0] === 'login' && !isLoggedIn()) {
      return router.push({ name: 'login' });
    } else if (segments[0] === 'register' && !isLoggedIn()) {
      return router.push({ name: 'register' });
    } else if (segments[0] === 'account') {
      return router.push({ name: 'settings' });
    } else if (segments[0] === 'library') {
      return router.push({ name: 'list', params: { list: 'library' } });
    } else if (subdomain && segments[0] === 'listen' && segments.length === 2) {
      // alert('wildcard: ' + subdomain + ' ' + segments[1])
      // This is a promo page with a wildcard subdomain (aka, the team's subdomain)
      return router.push({ name: 'player-page', params: { team: subdomain, promo: segments[1] } });
    } else {
      return router.push({ path: `/${path}` });
    }
  });

  function extractQueryParams(url: string): { [key: string]: string } {
    let queryParams: { [key: string]: string } = {};
    // Extract the part after the question mark
    const queryString = url.split('?')[1];

    if (!queryString) {
      return queryParams;
    }

    // Split the string into individual key=value pairs
    const pairs = queryString.split('&');

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1] || '');
      //@ts-ignore
      queryParams[key] = value;
    }

    return queryParams;
  }

  const platform = Capacitor.getPlatform();
  const sqlite = new SQLiteConnection(CapacitorSQLite);

  const pinia = createPinia();

  const app = createApp(App).use(IonicVue, {
    hardwareBackButton: true,
    mode: 'ios'
  })
    .use(router)
    .use(pinia)
    .use(FernandPlugin, {
      appId: process.env.VUE_APP_FERNAND_APP_ID
    });


  if (platform === "web") {
    // Create the 'jeep-sqlite' Stencil component
    const jeepSqlite = document.createElement("jeep-sqlite");
    document.body.appendChild(jeepSqlite);
    await customElements.whenDefined("jeep-sqlite");
    // Initialize the Web store
    await sqlite.initWebStore();
  }

  // Setup Global Helper Functions
  const rootSvg = require.context('./svgs/', true, /\.svg$/);
  const themeSvg = require.context('./svgs/theme/', true, /\.svg$/);

  app.config.globalProperties.$svg = (name: string) => {
    // Prioritize theme
    if (themeSvg.keys().includes(`./${name}.svg`)) {
      return themeSvg(`./${name}.svg`);
    }

    // Fallback to root
    return rootSvg(`./${name}.svg`);
  };

  app.config.globalProperties.$icon = (name: string, lib = 'fontawesome') => {
    // Prioritize theme
    if (themeSvg.keys().includes(`./${name}.svg`)) {
      return themeSvg(`./${name}.svg`);
    }

    // Fallback to root
    const defaultPath = lib ? `./icons/${lib}/${name}.svg` : `./icons/${name}.svg`;
    if (rootSvg.keys().includes(defaultPath)) {
      return rootSvg(defaultPath);
    }

    return rootSvg('./icons/circle-question.svg');
  };

  //@ts-ignore
  window.sqlite = sqlite

  const ret = await sqlite.checkConnectionsConsistency();
  const isConn = (await sqlite.isConnection("db", false)).result;
  let db;
  if (ret.result && isConn) {
    db = await sqlite.retrieveConnection("db", false);
  } else {
    db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
  }

  await db.open();
  const createLibraryApiCache = `
    CREATE TABLE IF NOT EXISTS lib_api_cache (
    url TEXT NOT NULL,
    content TEXT NOT NULL);`;

  const createHistoryApiCache = `
    CREATE TABLE IF NOT EXISTS history_api_cache (
    user_id INTEGER NOT NULL,
    track_id INTEGER NOT NULL,
    content TEXT NOT NULL);`;

  const createPromosApiCache = `
    CREATE TABLE IF NOT EXISTS promos_api_cache (
    promo_url TEXT NOT NULL,
    content TEXT NOT NULL);`;

  const createDownloadedPromosCache = `
  CREATE TABLE IF NOT EXISTS downloaded_promos_cache (
    user_id INTEGER NOT NULL,
    promo_id INTEGER NOT NULL,
    content TEXT NOT NULL);`;

  const createDownloadedFiles = `
  CREATE TABLE IF NOT EXISTS downloaded_files (
    book_id INTEGER NOT NULL,
    track_id INTEGER NOT NULL);`;

  const create_sync_playback_history = `
    CREATE TABLE "sync_playback_history" (
      "track_id"    INTEGER,
      "team_id"    INTEGER,
      "book_id"    INTEGER,
      "playback_rate"    REAL,
      "current_position"    REAL,
      "seconds_played_since_sync"    REAL,
      "dt_last_updated"    INTEGER,
      "promo_id"    INTEGER,
      "team_slug" TEXT,
      "promo_slug" TEXT,
      PRIMARY KEY("track_id")
);`

  const create_all_playback_history = `
    CREATE TABLE "all_playback_history" (
      "track_id"    INTEGER,
      "team_id"    INTEGER,
      "book_id"    INTEGER,
      "playback_rate"    REAL,
      "current_position"    REAL,
      "seconds_played_since_sync"    REAL,
      "dt_last_updated"    INTEGER,
      "promo_id"    INTEGER,
      "team_slug" TEXT,
      "promo_slug" TEXT,
      PRIMARY KEY("track_id")
    );
`
  try {
    await db.execute(createLibraryApiCache);
  }
  catch (e) { }
  try {
    await db.execute(createHistoryApiCache);
  }
  catch (e) { }
  try {
    await db.execute(createPromosApiCache);
  }
  catch (e) { }
  try {
    await db.execute(createDownloadedPromosCache);
  }
  catch (e) { }
  try {
    await db.execute(createDownloadedFiles)
  }
  catch (e) { }
  try {
    await db.execute(create_all_playback_history)
  }
  catch (e) { }
  try {
    await db.execute(create_sync_playback_history)
  } catch (e) {
    console.log(e)
  }

  router.isReady().then(() => {
    app.mount('#app');
  });
})
// eslint-disable-next-line
console.log(`v${process.env.VUE_APP_VERSION}`);
