import { defineStore } from 'pinia';
import { isPlatform } from '@ionic/vue';
import { Preferences } from '@capacitor/preferences';
import canReachRealNetwork from '@/utils/checkCaptive';
import { nextTick } from 'vue';
import api from '@/utils/api';


export const useLibraryStore = defineStore('library', {
  state: () => ({
    promosById: {},
    promosByList: {},
    noPromosByList: {},
    fullyLoadedByList: {},
    activePromoId: null,
    sortBy: "updated",
    viewingTab: "library",
    listRefreshRequired: false,
    lastViewedTab: localStorage.getItem('lastViewedTab', "library"),
  }),
  getters: {
    ids: (state) => [...new Set(Object.keys(state.promosById) || [])],
    allPromos: (state) => state.ids.map((id) => state.promosById[id]),
    promosByRoute: (state) =>
      Object.keys(state.promosById).reduce((obj, promoId) => {
        const promo = state.promosById[promoId];
        const teamSubdomain = promo.team?.subdomain;
        const promoSlug = promo.slug;
        if (!obj[teamSubdomain]) obj[teamSubdomain] = {};
        obj[teamSubdomain][promoSlug] = promo;
        return obj;
      }, {}),
    sortByLabel: (state) => {
      let label;
      switch (state.sortBy) {
        case 'atoz':
          label = 'Title (A-Z)';
          break;
        case 'ztoa':
          label = 'Title (Z-A)';
          break;
        case 'shortest':
          label = 'Duration (Shortest)';
          break;
        case 'longest':
          label = 'Duration (Longest)';
          break;
        case 'updated':
          label = 'Last Played';
          break;
        case 'created':
          label = 'Recently Added';
          break;
      }
      return label;
    },
  },

  actions: {
    setLastViewedTab(tab) {
      this.lastViewedTab = tab;
      nextTick(this.saveLastViewedTabToLocalStorage);
    },
    saveLastViewedTabToLocalStorage() {
      localStorage.setItem('lastViewedTab', this.lastViewedTab);
    },

    getPromoByRoute({ team, promo }) {
      return this.promosByRoute[team] ? this.promosByRoute[team][promo] : null;
    },

    getPromosByList({ list }) {
      return this.promosByList[list];
    },

    async addTitleToLibrary({ team, promo }) {
      try {
        await api.post(`/promo/${team}/${promo}/access`);
        await this.fetchPromo({ team, promo });
      } catch (e) {
        console.log(e);
        alert('Error adding to your library. Please try again or contact support.');
        return false;
      }
      return true;
    },

    async fetchPromos({ list = 'library', page = 1, resetResults = false }) {
      this.viewingTab = list;
      const label = getStoreKey(list);
      let data = null;

      const sqlite = window.sqlite;
      if (!sqlite) return this.getPromosByList({ list });
      const isConn = (await sqlite.isConnection("db", false)).result;
      let db;
      const ret = await sqlite.checkConnectionsConsistency();
      if (ret.result && isConn) {
        db = await sqlite.retrieveConnection("db", false);
      } else {
        db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
      }
      await db.open()

      
      if (list === 'downloaded' && isPlatform('capacitor')) {
        let dbData = (await db.query(`SELECT * FROM downloaded_promos_cache`)).values
        let promos = dbData.map(row => JSON.parse(row.content))

        if (this.sortBy === 'atoz') {
          promos.sort((a, b) => {
            if (a.name.toUpperCase() <= b.name.toUpperCase()) {
              return -1;
            } else {
              return 1;
            }
          });
        } else if (this.sortBy === 'ztoa') {
          promos.sort((a, b) => {
            if (a.name.toUpperCase() <= b.name.toUpperCase()) {
              return 1;
            } else {
              return -1;
            }
          });
        } else if (this.sortBy === 'shortest') {
          promos.sort((a, b) => {
            if (a.runtime_seconds >= b.runtime_seconds) {
              return 1;
            } else {
              return -1;
            }
          });
        } else if (this.sortBy === 'longest') {
          promos.sort((a, b) => {
            if (a.runtime_seconds >= b.runtime_seconds) {
              return -1;
            } else {
              return 1;
            }
          });
        } else if (this.sortBy === 'updated') {
          promos.sort((a, b) => {
            if (new Date(a.updated_at) - new Date(b.updated_at)) {
              return 1;
            } else {
              return -1;
            }
          });
        } else if (this.sortBy === 'created') {
          promos.sort((a, b) => {
            if (new Date(a.created_at) - new Date(b.created_at)) {
              return 1;
            } else {
              return -1;
            }
          });
        }

        data = {
          data: promos,
          total: promos.length,
          next_page_url: null
        };
      } else if (list === 'freeTitles') {
        let networkAvailable = await canReachRealNetwork();
        if (networkAvailable) {
          data = (await api.get(`/promo/browse?page=${page}&filterBy=free`)).data.promosPaginated;
        }
      } else {
        let networkAvailable = await canReachRealNetwork();
        if (networkAvailable) {
          data = (await api.get(`/library?label=${label}&page=${page}&sort=${this.sortBy}`)).data;
        }
      }

      if (resetResults) {
        delete this.promosByList[list];
        delete this.fullyLoadedByList[list];
      }

      if (Object.keys(data || {}).length) {
        const hasList = page > 1 && this.promosByList[list]?.length;
        // Set all the relevant state
        data?.data?.forEach((promo) => {
          // Set promosById
          this.promosById[promo.id] = promo;
          if (hasList) {
            this.promosByList[list] = [...this.promosByList[list], promo];
          }
        });

        if (data?.total === 0) {
          this.noPromosByList[list] = true;
        } else {
          this.noPromosByList[list] = false;
          // Set promosByList
          if (!hasList) {
            this.promosByList[list] = data?.data;
          }
        }

        if (data.next_page_url) {
          this.fullyLoadedByList[list] = false;
        } else {
          this.fullyLoadedByList[list] = true;
        }
      }

      return this.getPromosByList({ list });
    },

    async fetchPromo({ team, promo, caller }, force = false) {
      const url = `/promo/${team}/${promo}`;

      const sqlite = window.sqlite;
      if (!sqlite) return {
        cached: this.getPromoByRoute({ team, promo }) || {},
        promised: new Promise(res => res()),
      };
      const isConn = (await sqlite?.isConnection("db", false))?.result;
      let db;
      const ret = await sqlite.checkConnectionsConsistency();
      if (ret.result && isConn) {
        db = await sqlite.retrieveConnection("db", false);
      } else {
        db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
      }
      // await db.open()

      let networkAvailable = await canReachRealNetwork()
      let promised;

      if (!networkAvailable) {
        promised = db.query(`SELECT * FROM promos_api_cache WHERE promo_url = ?`, [
          url
        ]).then((values = []) => {
          return JSON.parse(values.values[0].content);
        });
      } else {
        let axiosConfig = {};
        // add 5 second max timeout if book is downloaded. this is to address an issue where user is
        // in a poor network area, but still has connectivity which prevents the book from loading
        if (this.viewingTab === 'downloaded') {
          axiosConfig = {
            timeout: 5000
          };
        }

        promised = api.get(url, axiosConfig).then(async ({ data }) => {
          if (data.status === 'success') {
            const promo = {
              ...data.promo,
              team: data.team || data.promo?.team || {},
              tracks: data.tracks || data.promo?.tracks || [],
              book: data.book || data.promo?.book || {},
            };
            this.promosById[promo.id] = promo;
            this.activePromoId = promo.id;
            if (isPlatform('capacitor')) {
              let success = false;
              while (!success) {
                try {
                  await db.run(`DELETE FROM promos_api_cache WHERE promo_url = ?`, [
                    url
                  ])
                  await db.run(`INSERT INTO promos_api_cache(promo_url, content) VALUES(?,?)`, [
                    url,
                    JSON.stringify(promo)
                  ]);
                  success = true;
                } catch (e) {
                  await new Promise(r => setTimeout(r, 2000));
                }
              }

            }
            return promo;
          } else {
            if (data.redirect) console.log('TODO: redirect to new promo');
            return {};
          }
        }).catch(async (e) => {
          let values = await db.query(`SELECT * FROM promos_api_cache WHERE promo_url = ?`, [
            url
          ]);
          return JSON.parse(values.values[0].content);
        });
      }



      return {
        cached: this.getPromoByRoute({ team, promo }) || {},
        promised,
      };
    },

    watch: {
      lastViewedTab: {
        handler: (newVal, oldVal) => {
          if (newVal !== oldVal) this.saveLastViewedTabToLocalStorage(newVal);
        },
        immediate: true,
      }
    }
  },
});

export const getStoreKey = (id) => {
  const storeKeysById = {
    downloaded: 'downloaded',
    library: 'library',
    added: 'recentAdds',
    recent: 'recents',
    purchases: 'purchases',
    finished: 'finished',
    all: 'all',
    inProgress: 'inProgress',
    notStarted: 'notStarted',
    freeTitles: 'freeTitles',
    archived: 'archived'
  };
  return storeKeysById[id];
};
