import { ref, onMounted, onBeforeUnmount } from 'vue';
// import AudioPlugin from '@/plugins/AudioPlugin';
import { Playback } from 'playback';
import { useAudioStore } from '@/stores/useAudioStore';
import { isPlatform } from '@ionic/vue';

const stateListenerMounted = ref(false);
const indexListenerMounted = ref(false);
const progressListenerMounted = ref(false);
const errorListenerMounted = ref(false);
const stateCallbacks = ref([]);
const progressCallbacks = ref([]);
const indexCallbacks = ref([]);
const errorCallbacks = ref([]);

function loopStateCallbacks(data) {
	stateCallbacks.value.forEach((callback) => {
		callback(data);
	});
}

function loopProgressCallbacks(data) {
	progressCallbacks.value.forEach((callback) => {
		callback(data);
	});
}

function loopIndexCallbacks(data) {
	indexCallbacks.value.forEach((callback) => {
		callback(data);
	});
}

function loopErrorCallbacks(data) {
	errorCallbacks.value.forEach((callback) => {
		callback(data);
	});
}

function webState(e){
	loopStateCallbacks(e.detail)
}

function webIndex(e){
	loopIndexCallbacks(e.detail)
}

function webProg(e){
	loopProgressCallbacks(e.detail)
}

function webErr(e){
	loopErrorCallbacks(e.detail)
}

export default function useAudioPluginListener({
	onStateChange = null,
	onIndexChange = null,
	onProgressChange = null,
	onAudioError = null,
}) {
	onMounted(() => {
		if (onStateChange) {
			stateCallbacks.value.push(onStateChange);
			if (stateListenerMounted.value === false) {
				if (isPlatform('capacitor')) {
					Playback.addListener('stateChange', loopStateCallbacks);
				} else {
					window.addEventListener('stateChange', webState)
				}
				stateListenerMounted.value = true;
			}
		}

		if (onIndexChange) {
			indexCallbacks.value.push(onIndexChange);
			if (indexListenerMounted.value === false) {
				if (isPlatform('capacitor')) {
					Playback.addListener('indexChange', loopIndexCallbacks);
				} else {
					window.addEventListener('indexChange', webIndex)
				}
				indexListenerMounted.value = true;
			}
		}

		if (onProgressChange) {
			progressCallbacks.value.push(onProgressChange);
			if (progressListenerMounted.value === false) {
				if (isPlatform('capacitor')) {
					Playback.addListener('progressChange', loopProgressCallbacks);
				} else {
					window.addEventListener('progressChange', webProg)
				}
				progressListenerMounted.value = true;
			}
		}

		if (onAudioError) {
			errorCallbacks.value.push(onAudioError);
			if (errorListenerMounted.value === false) {
				if (isPlatform('capacitor')) {
					Playback.addListener('audioError', loopErrorCallbacks);
				} else {
					window.addEventListener('audioError', webErr)
				}
				errorListenerMounted.value = true;
			}
		}
	});

	onBeforeUnmount(() => {
		if (stateListenerMounted.value === true) {
			Playback.removeListener('stateChange');
			window.removeEventListener('stateChange', webState)
			stateListenerMounted.value = false;
		}
		if (progressListenerMounted.value === true) {
			Playback.removeListener('progressChange');
			window.removeEventListener('progressChange', webProg)
			progressListenerMounted.value = false;
		}
		if (indexListenerMounted.value === true) {
			Playback.removeListener('indexChange');
			window.removeEventListener('indexChange', webIndex)
			indexListenerMounted.value = false;
		}
		if (errorListenerMounted.value === true) {
			Playback.removeListener('audioError');
			window.removeEventListener('audioError', webErr)
			errorListenerMounted.value = false;
		}
	});
}
