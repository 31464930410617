<script setup>
import { computed, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/useAuthStore';
import { isPlatform } from '@ionic/vue';
import { Browser } from '@capacitor/browser';
import { networkConnected } from '@/composables/useNetwork';

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonText,
  menuController
} from '@ionic/vue';

const prefix = 'list';
let appPages = [
  {
    title: 'Library',
    url: `/${prefix}/library`,
    icon: 'books',
  },
  // {
  //   title: 'Newly Added',
  //   url: `/${prefix}/added`,
  //   icon: 'sparkles',
  // },
  // {
  //   title: 'Recent Listens',
  //   url: `/${prefix}/recent`,
  //   icon: 'clock',
  // },
  {
    title: 'In Progress',
    url: `/${prefix}/inProgress`,
    icon: 'headphones-simple',
  },
  {
    title: 'Not Started',
    url: `/${prefix}/notStarted`,
    icon: 'book-open',
  },
  // {
  //   title: 'Free Titles',
  //   url: `/${prefix}/freeTitles`,
  //   icon: 'gift',
  // },
  {
    title: 'Purchases',
    url: `/${prefix}/purchases`,
    icon: 'wallet',
  },
  {
    title: 'Archived',
    url: `/${prefix}/archived`,
    icon: 'box-archive',
  },
  // {
  //   title: 'Finished',
  //   url: `/${prefix}/finished`,
  //   icon: '',
  // },
  {
    title: 'Search',
    url: `/search`,
    icon: 'magnifying-glass',
  },
];

if (isPlatform('capacitor')) {
  appPages.unshift({
    title: 'Downloads',
    url: `/${prefix}/downloaded`,
    icon: 'circle-check',
  });
}

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

const isSelected = (url) => {
  // return true if the beginning of route.path is the url using regex
  return new RegExp(`^${url}`).test(route.path);
};

const props = defineProps({
  contentId: String,
});

window.addEventListener('closeMenuGesture', () => {
  menuController.close();
});

const openWebView = async (url) => {
  await Browser.open({ url });
};

const supportLink = process.env.VUE_APP_SUPPORT;
const accountLink = process.env.VUE_APP_ACCOUNT;
const appName = process.env.VUE_APP_APP_NAME;
const supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;

const routerDirection = computed(() => {
  if (route.name === prefix) return 'root';
  return 'back';
});

function handleLogoClick() {
  const host = process.env.VUE_APP_WEB_HOST;
  if (host) {
    openWebView(`https://${host}`);
  }
}
</script>

<template>
  <ion-menu class="AppMenu" :content-id="props.contentId" :swipe-gesture="true" type="overlay">
    <ion-content>
      <ion-list id="main_menu">
        <ion-menu-toggle auto-hide="false">
          <ion-list-header class="mb-gr-6 leading-gr-7" style="padding-top: 12px;">
            <div style="display: flex; justify-content: space-between; align-items: center; width:100%;">
              <button @click.prevent.stop="handleLogoClick" class="bg-logo focus:ring-0">{{ appName }}</button>
              <button id="btn_close" class="p-grf-5 leading-gr-6 text-primary-600 hover:text-primary-400 focus:ring-0">
                <ion-icon class="text-grf-6" :src="$icon('xmark-large')" />
              </button>
            </div>
          </ion-list-header>
          <template v-if="authStore.user" v-for="(p, i) in appPages" :key="i">
            <ion-item :router-direction="routerDirection" :router-link="p.url" lines="none" detail="false"
              :class="{ selected: isSelected(p.url) }">
              <ion-icon class="text-grf-double-5" slot="start" :icon="$icon(p.icon)"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </template>
        </ion-menu-toggle>
      </ion-list>

      <!-- 
      <ion-list id="account_menu" v-if="authStore.user">
        <ion-list-header>Account</ion-list-header>
        <ion-item lines="none">
          <ion-text>{{ authStore.user?.email }}</ion-text>
        </ion-item>
        <ion-item :href="accountLink" target="_blank" lines="none" detail="false">
          <ion-icon class="text-grf-double-5" slot="start" :ios="openOutline" :md="openOutline"></ion-icon>
          <ion-label>Manage Account</ion-label>
        </ion-item>
        <ion-item lines="none" detail="false" @click="authStore.logout({ router })">
          <ion-icon class="text-grf-double-5" slot="start" :ios="logOut" :md="logOut"></ion-icon>
          <ion-label>Log out</ion-label>
        </ion-item>
      </ion-list>
       -->
       <ion-list id="settings_menu">
        <ion-menu-toggle auto-hide="false">
          <ion-item v-if="authStore.user" router-link="/settings" lines="none" detail="false"
            :class="{ selected: isSelected('/settings') }">
            <ion-icon class="text-grf-double-5" slot="start" :icon="$icon('circle-user')"></ion-icon>
            <ion-label>Account Settings</ion-label>
          </ion-item>
          <template v-else>
            <ion-item router-link="/login" lines="none" detail="false"
              :class="{ selected: isSelected('/login') }">
              <ion-icon class="text-grf-double-5" slot="start" :icon="$icon('right-to-bracket')"></ion-icon>
              <ion-label>Login</ion-label>
            </ion-item>
          </template>

          <!-- <ion-item @click="openWebView(supportLink)" lines="none" detail="false">
            <ion-icon class="text-grf-double-5" slot="start" :icon="$icon('user-group')"></ion-icon>
            <ion-label>Contact Support</ion-label>
          </ion-item> -->

          <ion-item v-if="!networkConnected" :href="`mailto:${supportEmail}`" lines="none" detail="false">
            <ion-icon class="text-grf-double-5" slot="start" :icon="$icon('user-group')"></ion-icon>
            <ion-label>Contact Support</ion-label>
          </ion-item>
          <ion-item v-else router-link="/support" lines="none" detail="false"
            :class="{ selected: isSelected('/support') }">
            <ion-icon class="text-grf-double-5" slot="start" :icon="$icon('user-group')"></ion-icon>
            <ion-label>Contact Support</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<style scoped>
.menu-pane-visible #btn_close {
  @apply hidden;
}
</style>
