// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import Router from 'vue-router';
import { useAuthStore } from '@/stores/useAuthStore';
import { useAudioStore } from '@/stores/useAudioStore';
import { useLibraryStore } from '@/stores/useLibraryStore';
import { Network } from '@capacitor/network';
import canReachRealNetwork from '@/utils/checkCaptive'
import { Browser } from '@capacitor/browser';
import Tabs from '@/components/Tabs.vue';

const openWebView = async (url) => {
  await Browser.open({ url });
};

const routes: Array<Router.RouteRecordRaw> = [
  {
    path: '',
    name: 'root',
    redirect: '/list/library',
  },
  {
    path: '/',
    component: Tabs,
    children: [
      // {
      //   path: '/home',
      //   name: 'home',
      //   component: () => import('../views/HomePage.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     getTitle() {
      //       return 'Home';
      //     }
      //   }
      // },
      { 
        path: '/search/:query?',
        name: 'search',
        component: () => import('../views/SearchPage.vue'),
        meta: {
          requiresAuth: true,
          getTitle() {
            return 'Search';
          }
        }
      },
      {
        path: '/list/:list/:page?',
        component: () => import('../views/ListPage.vue'),
        props: true,
        name:'list',
        meta: {
          requiresAuth: true,
          getTitle: (params: object) => {
            const titlesById: { [key: string]: string } = {
              downloaded: 'Downloads',
              library: 'Library',
              added: 'Newly Added',
              recent: 'Recent Listens',
              purchases: 'Purchases',
              finished: 'Finished',
              inProgress: 'In Progress',
              notStarted: 'Not Started',
              freeTitles: 'Free Titles',
              archived: 'Archived'
            };
            return titlesById[params.list];
          },
          getStoreKey: (id: string) => {
            // @see: useLibraryStore.js
            const storeKeysById: { [key: string]: string } = {
              downloaded: 'downloaded',
              library: 'library',
              added: 'recentAdds',
              recent: 'recents',
              purchases: 'purchases',
              finished: 'finished',
              inProgress: 'inProgress',
              notStarted: 'notStarted',
              freeTitles: 'freeTitles',
              archived: 'archived'
            };
            return storeKeysById[id];
          },
        },
        beforeEnter: (to, from, next) => {
          const validLists = ['downloaded', 'library', 'added', 'recent', 'purchases', 'finished', 'inProgress', 'notStarted', 'freeTitles', 'archived'];
          if (validLists.includes(to.params.list)) {
            const libraryStore = useLibraryStore();
            if (libraryStore.listRefreshRequired) {
              libraryStore.listRefreshRequired = false;
              window.location.reload();
              return next();
            }
            // window.dispatchEvent(new Event('closePlayerGesture'));  
            try {
              libraryStore.fetchPromos(to.params);
            } catch (error) {
              console.error(error);
            }
            next();
          } else {
            next({ name: 'not-found', params: { catchAll: `list/${to.params.list}/${to.params.page}` } });
          }
        }
      },
      {
        path: '/listen/:team/:promo',
        props: true,
        component: () => import('../views/PlayerPage.vue'),
        name:'player-page',
        meta: {
          requiresAuth: true,
          getTitle(params) {
            const audioStore = useAudioStore();
            const promo = audioStore?.playlistsBySlug[params?.promo];
            return promo?.book?.title || '';
          },
        },
        beforeEnter: async (to, from, next) => {
          const libraryStore = useLibraryStore();
          
          // get promo from library
          const res = await libraryStore.fetchPromo(to.params);
          const promo = Object.keys(res.cached).length ? res.cached : await res.promised
          if (!promo?.id) {
            // Try to open on the website
            const webHost = process.env.VUE_APP_WEB_HOST;
            const url = `https://${to.params.team}.${webHost}/${to.params.promo}`;
            const res = await openWebView(url);
            if (res) {
              next({ name: 'list', params: { list: 'library' }});
            } else {
              next({ name: 'not-found', params: { catchAll: `missing-promo` } });
            }
          } else {
            // Check if the user has access to the promo
            const hasAccess = promo.permissions?.canListen;
            // Check if the promo is available
            if (!hasAccess) {
              const isAvailable = promo.permissions?.canView && promo.permissions?.canSignup && !promo.is_selling;
              if (isAvailable) {
                const hasTracks = promo.tracks?.length;
                if (hasTracks) {
                  if (confirm('Add to your library?')) {
                    const res = await libraryStore.addTitleToLibrary({
                      team: promo.team.subdomain,
                      promo: promo.slug
                    })
                    if (res) { 
                      next();
                    } else {
                      // Redirect to the previous page
                      next(from);
                    }
                  } else {
                    // Redirect to the previous page
                    next(from);
                  }
                } else { 
                  next();
                }
              } else {
                alert('You do not have access to this title');
                // Redirect to the previous page
                next(from);
              }
            } else {
              next();
            }
          }
        }
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('../views/SettingsPage.vue'),
        meta: {
          requiresAuth: true,
          getTitle(params) {
            return 'Settings';
          }
        }
      },
      {
        path: '/support',
        name: 'support',
        component: () => import('../views/SupportPage.vue'),
        meta: {
          getTitle(params) {
            return 'Support';
          }
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue'),
    meta: {
      getTitle() {
        return 'Welcome back! Please, log in.';
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterPage.vue'),
    meta: {
      getTitle() {
        return 'Sign up today — it\'s quick and easy!';
      },
    },
  },
  // {
  //   path: '/forgot-password',
  //   name: 'forgot-password',
  //   component: () => import('../views/ForgotPasswordPage.vue'),
  //   meta: {
  //     getTitle() {
  //       return 'Forgot your password?';
  //     },
  //   },
  // },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../views/NotFoundPage.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next): Promise<void> => {
  const authStore = useAuthStore();
  const libraryStore = useLibraryStore();

  if (to.name === 'list') {
    libraryStore.setLastViewedTab(to.params.list);
  }

  if (to.meta.requiresAuth && !authStore.hasAuth) {
    // eslint-disable-next-line
    let query = {};
    if (to.name) {
      query.name = to.name;
    } else if (to.path) {
      query.path = to.path;
    }

    try {
      const authPassed = await authStore.checkAuth();
      if (!authPassed) return next({ name: 'login', query });
    } catch {
      return next({ name: 'login', query });
    }

    let networkReached = await canReachRealNetwork()

    if(!networkReached){
     return next({name:'list', params:{list:'downloaded', page:''}})
    }

  }

  next();
  const pageTitle = await to.meta?.getTitle(to.params);
  const appName = process.env.VUE_APP_APP_NAME;
  document.title = pageTitle ? `${pageTitle} / ${appName} Library` : `${appName} Library`;
});

export default router;
