import { Network } from '@capacitor/network';
import axios, { resolve } from 'axios';


async function canReachRealNetwork() {
    let networkStatus = await Network.getStatus()
    if (networkStatus.connected === false) {
        return false
    }
    const url = "https://captive.apple.com/hotspot-detect.html";
    try {
        const response = await axios.get(url, { timeout: 5000 }); // 5 seconds timeout

        if (response.status === 200) {
            return response.data.toLowerCase().includes("success");
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export default canReachRealNetwork