/* eslint-disable */
import { registerPlugin } from '@capacitor/core';
import type { PlaybackPlugin } from './definitions';

const Playback = registerPlugin<PlaybackPlugin>('Playback', {
  web: () => import('./web').then(m => new m.PlaybackWeb()),
});

export * from './definitions';
export { Playback };
