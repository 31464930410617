import { ref, onMounted, onUnmounted, watch } from 'vue';
import { isPlatform } from '@ionic/vue';

const unlocked = ref(false);

function removeUnlockListener() {
	document.removeEventListener('touchstart', unlockAudio, false);
	document.removeEventListener('touchend', unlockAudio, false);
	document.removeEventListener('click', unlockAudio, false);
	document.removeEventListener('keydown', unlockAudio, false);
}

function unlockAudio(ev) {
	if (unlocked.value) return false;

	try {
		var ctx = new AudioContext();
		var source = ctx.createBufferSource();
		source.buffer = ctx.createBuffer(1, 1, 22050);
		source.connect(ctx.destination);

		// Play the empty buffer.
		if (typeof source.start === 'undefined') {
			source.noteOn(0);
		} else {
			source.start(0);
		}
		unlocked.value = true;
	} catch (e) {
		console.error(e);
	}

	removeUnlockListener();
}

export default function useAudioUnlock() {
	if (isPlatform('capacitor')) {
		unlocked.value = true;
		return;
	}

	onMounted(() => {
		document.addEventListener('touchstart', unlockAudio, true);
		document.addEventListener('touchend', unlockAudio, true);
		document.addEventListener('click', unlockAudio, true);
		document.addEventListener('keydown', unlockAudio, true);
	});

	onUnmounted(() => {
		removeUnlockListener();
	});

	watch(unlocked, (isUnlocked, wasUnlocked) => {
		if (!wasUnlocked && isUnlocked) console.log('Audio unlocked!');
	});
}
