<script setup>
import { 
	IonIcon, 
	IonLabel, 
	IonPage, 
	IonRouterOutlet,
	IonTabBar, 
	IonTabButton, 
	IonTabs, 
	actionSheetController,
} from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import { openListSelect } from '@/composables/useListSelect';

const route = useRoute();
const router = useRouter();
</script>

<template>
	<ion-page>
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar slot="bottom" class="backdrop-blur bg-light/90 h-[3rem]">
				<!-- <ion-tab-button tab="Home" href="/home">
					<ion-icon v-if="route.name === 'home'" :icon="$icon('house-blank-solid')"></ion-icon>
					<ion-icon v-else :icon="$icon('house-blank')"></ion-icon>
					<ion-label class="font-secondary font-bold">Home</ion-label>
				</ion-tab-button> -->

				<ion-tab-button tab="Free" href="/list/freeTitles">
					<ion-icon class="mb-0" v-if="route.name === 'list' && route.params?.list === 'freeTitles'" :icon="$icon('sparkles-solid')"></ion-icon>
					<ion-icon class="mb-0" v-else :icon="$icon('sparkles')"></ion-icon>
					<ion-label class="text-grf-half-6 font-secondary font-bold">Free Titles</ion-label>
				</ion-tab-button>

				<ion-tab-button tab="Search" href="/search">
					<ion-icon class="mb-0" v-if="route.name === 'search'" :icon="$icon('magnifying-glass-waveform-solid')"></ion-icon>
					<ion-icon class="mb-0" v-else :icon="$icon('magnifying-glass')"></ion-icon>
					<ion-label class="text-grf-half-6 font-secondary font-bold">Search</ion-label>
				</ion-tab-button>

				<ion-tab-button tab="Library" button @click="openListSelect(route, router)" :class="route.name === 'list' && route.params?.list !== 'freeTitles' ? 'tab-selected' : ''">
					<ion-icon class="mb-0" v-if="route.name === 'list' && route.params?.list !== 'freeTitles'" :icon="$icon('books-solid')"></ion-icon>
					<ion-icon class="mb-0" v-else :icon="$icon('books')"></ion-icon>
					<ion-label class="text-grf-half-6 font-secondary font-bold">Library</ion-label>
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>