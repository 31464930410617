import { ref } from 'vue';

export default {
  install(app, options) {
    const isLoaded = ref(false);
    if (options?.appId && typeof window.Fernand !== "function") {
      (function (w){if (typeof w.Fernand !== "function") {var f = function () {f.q[arguments[0] == 'set' ? 'unshift' : 'push'](arguments);};f.q = [];w.Fernand = f;}})(window);
      
      window.Fernand('init', {  
        show: false,
        open: false,
        hideIcon: true,
        ...options
      });

      const script = document.createElement('script');
      script.async = true;
      script.src = "https://messenger.getfernand.com/client.js";
      document.head.appendChild(script);
      script.onload = () => {
        isLoaded.value = true;
        app.provide('Fernand', window.Fernand);
      }
    }
    
    app.provide('Fernand', window.Fernand);
    app.provide('Fernand.isLoaded', isLoaded);
  }
};