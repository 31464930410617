import { DateTime } from 'luxon';

export const formatLocalFromUTC = (timestamp, format = null) => {
  return DateTime.fromISO(timestamp).toLocaleString(
    format
      ? // eslint-disable-next-line
        DateTime.hasOwnProperty(format)
        ? DateTime[format]
        : format
      : Object.assign(DateTime.DATETIME_HUGE, {
          weekday: 'short',
          month: 'short',
          timeZoneName: 'short',
        }),
  );
};

export const getTimeZoneName = () => {
  return DateTime.local().toFormat('z');
};

export const localToUtc = (timestamp) => {
  return timestamp ? DateTime.fromISO(timestamp).setZone('utc') : null;
};

export const convertTimeHHMMSS = (val) => {
  if(val <= 0){
    return "00:00"
  }
  let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
  return hhmmss.indexOf('00:') === 0 ? hhmmss.substr(3) : hhmmss;
};
