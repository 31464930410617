<script setup lang="ts">
import AppMenu from '@/components/AppMenu.vue';
import AudioPlayerControlBar from '@/components/AudioPlayerControlBar';
import { IonApp, IonIcon, IonRouterOutlet, IonSplitPane, isPlatform } from '@ionic/vue';
import { onMounted, ref } from 'vue';
import { useAuthStore } from '@/stores/useAuthStore';
import { SplashScreen } from '@capacitor/splash-screen';
import { peopleOutline } from 'ionicons/icons';
import { Browser } from '@capacitor/browser';
import { networkConnected } from '@/composables/useNetwork';

if (isPlatform('capacitor')) {
  onMounted(() => {
    SplashScreen.hide();

    let menu = document.querySelector('ion-menu')!

    let touchstartX = 0
    let touchendX = 0

    function checkDirection() {
      if (touchendX < touchstartX) {
        let evt = new Event('closeMenuGesture')
        window.dispatchEvent(evt)
      }
    }

    menu.addEventListener('touchstart', e => {
      touchstartX = e.changedTouches[0].screenX
    })

    menu.addEventListener('touchend', e => {
      touchendX = e.changedTouches[0].screenX
      checkDirection()
    })
  })
}

onMounted(() => {
  document.body.classList.remove('pre-init');
});


let authStore = useAuthStore()
let hidePaywall = ref(true)

setInterval(() => {
  if (authStore.user) {
    if (!authStore.user['email_verified_at']) {
      let createdDate = (new Date() - new Date(authStore.user['created_at'])) / 1000
      if (createdDate > 60 * 60 * 24) {
        hidePaywall.value = false
      }
    }
  }
}, 1000)

async function logout() {
  await authStore.logout()
}

const accountLink = process.env.VUE_APP_ACCOUNT
const supportLink = process.env.VUE_APP_SUPPORT
const openWebView = async (url) => {
  await Browser.open({ url });
};
</script>

<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <app-menu content-id="main-content" />
      <ion-router-outlet id="main-content" />
    </ion-split-pane>
    <audio-player-control-bar />
  </ion-app>
  <div :class="networkConnected ? 'delay-1000 translate-y-full' : ''" class="duration-300 fixed z-40 bottom-0 text-center text-xs h-gr-half-7 leading-gr-half-7 inset-x-0 border-b border-white/20">
    <div v-if="networkConnected" class="bg-success-400 text-black dark:no-change ">You are back online!</div>
    <div v-else class="text-white bg-black">You are offline!</div>
  </div>
  <div
    class="fixed inset-0 flex bg-primary-darkest/90 dark:bg-neutral-100/90 items-center justify-center z-[100] backdrop-blur"
    :hidden="hidePaywall">
    <div class="card !bg-light pt-gr-6 mx-gr-6">
      <p>To access your library and continue listening, please verify your email address.</p>
      <p class="mt-gr-3 text-sm">After verifying email, please restart app.</p>
      <form @submit.prevent="authStore.resendVerificationEmail">
        <button class="mt-gr-6 btn btn-primary flex w-full">Resend Verification Email</button>
      </form>
      <div class="mt-gr-7 flex justify-between items-center">
        <button @click="logout" class="btn btn-yellow">Logout</button>
        <button @click="openWebView(supportLink)" class="link">
          Contact Support
          <ion-icon :src="$icon('arrow-up-right-from-square')" class="fa-xs fa-fw -translate-y-gr-1" />
        </button>
      </div>
    </div>
  </div>
</template>