// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { defineStore } from 'pinia';
import { getActivePinia } from 'pinia';
import { getQuery } from '@/utils/url';
import { isLoggedIn, setAuthTokens, getAccessToken } from 'axios-jwt';
import { isPlatform } from '@ionic/vue';
import { Playback } from 'playback';
import { Preferences } from '@capacitor/preferences';
import { useAudioStore } from '@/stores/useAudioStore';
import api, { login, logout } from '@/utils/api';
import canReachRealNetwork from '@/utils/checkCaptive'
import axios, { resolve } from 'axios';


const validRedirectKeys = ['path', 'name'];

export const useAuthStore = defineStore('auth', {
	state: () => ({
		user: null,
		redirect: {},
	}),

	getters: {
		hasAuth: (state) => state.user !== null,
		hasRedirect: (state) => {
			const redirect = state.redirect || {};
			return Object.keys(redirect).some((key) => validRedirectKeys.includes(key));
		},
	},

	actions: {
		async checkAuth() {
			let networkAvailable = await canReachRealNetwork()
			let savedAuth = (await Preferences.get({ key: 'auth' }))?.value;
			if (savedAuth) {
				savedAuth = JSON.parse(savedAuth)
				await Playback.setBearerToken({ bearerToken: `Bearer ${savedAuth.data.authorization.token}` })
			}
			if (!networkAvailable && savedAuth) {
				setAuthTokens({
					accessToken: savedAuth.data.authorization.token,
					refreshToken: savedAuth.data.authorization.token,
				})
				this.user = savedAuth.data.user
				await Playback.setBearerToken({ bearerToken: `Bearer ${getAccessToken()}` })
				return true
			} else if (isLoggedIn()) {
				try {
					if (isPlatform('capacitor')) {
						const res = await this.requestRefresh(savedAuth.data.authorization.token);
						if (res === false) {
							return false;
						}
					}
					const { data = {} } = await api.get('/auth/user');
					if (Object.keys(data).length) {
						this.user = data;
						await Playback.setBearerToken({ bearerToken: `Bearer ${getAccessToken()}` })
						return true;
					}
				} catch (e) {
					try {
						setAuthTokens({
							accessToken: savedAuth.data.authorization.token,
							refreshToken: savedAuth.data.authorization.token,
						})
						this.user = savedAuth.data.user
						return true
					} catch (e) {
						return false
					}
				}
			}
			return false;
		},

		async login({ form, router }) {
			try {
				this.user = await login(form);
			} catch (err) {
				console.log('err -->', err);
				alert(err || `We're sorry. This doesn't seem to be working right now. Please try again later or contact support.`);
				logout()
				return;
			}

			this.redirect = getQuery() || {};
			if (this.hasRedirect) {
				router.push(this.redirect);
				this.redirect = {};
				return;
			} else {
				router.push({ name: 'list', params: { list: 'library' } });
				return;
			}
		},

		async logout() {
			setAuthTokens({
				accessToken: "",
				refreshToken: "",
			})
			await logout();

			try {
				localStorage.clear()
				await Preferences.clear();
	
				const sqlite = window.sqlite;
				const isConn = (await sqlite.isConnection("db", false)).result;
				let db;
				const ret = await sqlite.checkConnectionsConsistency();
				if (ret.result && isConn) {
					db = await sqlite.retrieveConnection("db", false);
				} else {
					db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
				}
				await db.open()
	
				let tablesDb = (await db.query(`SELECT name FROM sqlite_master WHERE type='table'`)).values
				let tables = tablesDb.map(row => row.name)
				for (let table of tables) {
					try {
						await db.query(`DROP TABLE ${table}`)
					} catch (e) { }
				}
			} catch (e) {
				console.error('logout error', e);
			}


			if (isLoggedIn()) return alert('Please try again!');

			const audioStore = useAudioStore();
			audioStore.beforeStoreReset();
			//TODO: Update the UI to hide the player before navigating to the login page

			// map through that list and use the **$reset** fn to reset the state
			getActivePinia()._s.forEach((store) => store.$reset());

			// router.push({ name: 'login' });
			window.location.reload();
		},

		async requestRefresh(refresh) {
			try {
				// Notice that this is the global axios instance, not the axiosInstance!  <-- important
				const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/auth/refresh`, { refresh });

				await Preferences.set({
					key: 'auth',
					value: JSON.stringify(response),
				});
				await Playback.setBearerToken({ bearerToken: `Bearer ${response.data.authorization.token}` })
				setAuthTokens({
					accessToken: response.data.authorization.token,
					refreshToken: response.data.authorization.token,
				})
				return {
					accessToken: response.data.authorization.token,
					refreshToken: response.data.authorization.token,
				};
			}
			catch (e) {
				console.error('refresh error', e);
				this.logout();
				return false;
			}
		},

		async updateUser({ form }) {
			console.log('updateUser', form)
			let formData = {}
			if (form?.name) formData.name = form.name
			if (form?.email) formData.email = form.email
			const { data = {} } = await api.patch('/auth/user', formData);
			if (data.status === 'success') {
				if (data?.user) {
					this.user = data.user;
					if (form?.email && !this.user?.email_verified_at) {
						alert('Please verify your new email address. A verification email has been sent to you.')
					}
				}
				return true;
			} else {
				alert(data.message || 'An error occurred. Please try again.');
				return data.errors || {};
			}
		},

		async register({ form, router }) {
			const { data } = await api.post('/auth/register', form);

			if (data?.errors) {
				return data;
			}

			return this.login({ form: { email: form.email, password: form.password }, router });
		},

		async resetPassword({ form }) {
			console.log('TODO: resetPassword', form);
		},

		async updatePassword({ form }) {
			let formData = {}
			if (form?.password) formData.password = form.password
			if (form?.new_password) formData.new_password = form.new_password
			if (form?.confirm_password) formData.confirm_password = form.confirm_password

			const { data = {} } = await api.post('/auth/reset-password', formData);
			if (data.status === 'success') {
				alert('Password updated successfully');
				return true;
			} else {
				alert(data.message || 'An error occurred. Please try again.');
				return false;
			}
		},

		async confirmDelete({ router }) {
			if (confirm('Are you sure you want to delete your account? This action is irreversible.')) {
				const { data = {} } = await api.delete('/auth/user');
				if (data.status === 'success') {
					alert('Account deleted successfully');
					this.logout();
					return true;
				} else {
					alert(data.message || 'An error occurred. Please try again.');
					return false;
				}
			}
		},

		async resendVerificationEmail() {
			if (isLoggedIn()) {
				const { data = {} } = await api.post('/auth/resend-verification-email');
				if (data.status === 'success') {
					alert('Verification email sent successfully');
					return true;
				} else {
					alert(data.message || 'An error occurred. Please try again.');
					return false;
				}
			} else {
				this.logout();
				alert('Please login first.');
				window.location.reload();
			}
		}
	},
});
