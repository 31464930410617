export const makeQuery = (params = {}) => {
  let query = Object.keys(params).reduce((arr, key) => {
    let val = params[key];
    arr.push(`${key}=${val}`);
    return arr;
  }, []);
  return query.length ? `?${query.join('&')}` : '';
};

export const getQuery = (target = null) => {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  var queryObj = {};
  for (var i = 0; i < vars.length; i++) {
    var [key, val] = vars[i].split('=');
    if (target === key) return decodeURI(val);
    if (key) queryObj[key] = val;
  }
  return target ? null : queryObj;
};
