import { ref } from 'vue';
import { Network } from '@capacitor/network';

const networkConnected = ref(false);
const networkType = ref(null);

async function updateNetworkStatus() {
	const res = await Network.getStatus();
	networkConnected.value = res.connected;
	networkType.value = res.type;
	return res;
}

const networkCallbacks = ref([]);
const hasAddedListener = ref(false);

function addNetworkListener(callback = null) {
	if (callback) {
		networkCallbacks.value.push(callback);
	}
	if (hasAddedListener.value) {
		return callback ? true : false;
	}
	Network.addListener('networkStatusChange', async () => {
		const res = await updateNetworkStatus();
		networkCallbacks.value.forEach(cb => cb(res));	
	});
	hasAddedListener.value = true;
	return true;
}

function removeNetworkListener() {
	Network.removeAllListeners();
}




// Initial network status
updateNetworkStatus();
addNetworkListener();

// For testing purposes:
// setTimeout(()=>{networkConnected.value = true}, 5000);

export {
	networkConnected,
	networkType,
	addNetworkListener,
	removeNetworkListener
};