<script setup>
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { IonImg, IonIcon, IonModal, IonRange, IonLabel, isPlatform } from '@ionic/vue';
import useAudioUnlock from '@/composables/useAudioUnlock';
import useAudioPluginListener from '@/composables/useAudioPluginListener';
import { useAudioStore } from '@/stores/useAudioStore';
import { convertTimeHHMMSS } from '@/utils/date';
import { Playback } from 'playback';
import { getExpiry } from '@/utils/expiry';
import { useRoute, useRouter } from 'vue-router';
import { placeholder } from '@/utils/placeholder';

// Initialize -------------------------------------
const router = useRouter();
const audioStore = useAudioStore();
const isIos = isPlatform('ios')
const isMobile = isPlatform('mobile');
useAudioUnlock();


// Modal -------------------------------------
const modal = ref(null);
const modalIsOpen = computed(() => audioStore?.activeTrackId !== null);
const showLargeModal = ref(false);
const showSleepOverlay = ref(false);
const showRateOverlay = ref(false);
const showOverlay = computed(() => showSleepOverlay.value || showRateOverlay.value);
const showCarModeModal = ref(false);

watch(() => audioStore.showLargePlaybackModal, (newVal, oldVal) => {
  if (newVal !== oldVal) showLargeModal.value = newVal;
});

const initialTouchY = ref(0);
function handleTouchStart(e) {
  initialTouchY.value = e.touches[0].clientY;
}
function handleTouchMove(e) {
  let touchY = e.touches[0].clientY;
  if (initialTouchY.value - touchY > 10) {
    showLargeModal.value = true;
  }
}
onMounted(() => {
  modal.value.$el.addEventListener('touchstart', handleTouchStart);
  modal.value.$el.addEventListener('touchmove', handleTouchMove);
});
onUnmounted(() => {
  modal.value.$el.removeEventListener('touchstart', handleTouchStart);
  modal.value.$el.removeEventListener('touchmove', handleTouchMove);
});

function toggleOverlay(overlay) {
  showSleepOverlay.value = overlay === 'sleep';
  showRateOverlay.value = overlay === 'rate';
}


// Cover Art -------------------------------------
const blurredCover = ref(null);
const cachedCover = ref(placeholder);
watch(() => audioStore?.activePlaylist?.book?.cover, async (newUrl) => {
  if (newUrl) {
    if (isPlatform('capacitor')) {
      const img = (await Playback.getImage({ artUrl: newUrl })).img;
      cachedCover.value = `data:image/png;base64,${img}`;
    } else {
      cachedCover.value = `${newUrl}?h=1200&q=1&auto=format`;
    }
    blurredCover.value = `${newUrl}?h=1200&q=1&auto=format&blur=300`;
  } else {
    cachedCover.value = placeholder;
    blurredCover.value = placeholder;
  }
}, { immediate: true });


// Playing Track -------------------------------------
const isPlaying = computed(() => audioStore?.activeTrackStatus === 'playing');
const isLoading = computed(() => audioStore?.activeTrackStatus === 'loading');
const isErrored = computed(() => audioStore?.activeTrackStatus === 'error');
const isPaused = computed(() => audioStore?.activeTrackStatus === 'paused');
const showLoadingIcon = computed(() => isLoading.value || audioStore?.showLoading);
const showPlayIcon = computed(() => isPaused.value);
const showPauseIcon = computed(() => isPlaying.value);


// Scrubbing -------------------------------------
const lockScrub = ref(false);

async function rangeChange(event) {
  if (externalChange.value) return;
  if (event.detail.value !== audioStore.activeTrackCurrentTime) {
    const position = (event.detail.value / audioStore.activeTrackDuration) * 100;
    await audioStore.seekTo(position);
  }
  setTimeout(() => {
    lockScrub.value = false;
  }, 1000);
}

const totalRemainingTime = ref('');
const tempTime = ref(0);

function scrubDrag(value) {
  if (lockScrub) {
    tempTime.value = value.detail.value;
    totalRemainingTime.value = computeEntireTimeRemaining(value.detail.value);
  }
}

async function scrub(seconds, canChangeTracks = false) {
  await audioStore.scrub(audioStore.activeTrackCurrentTime + seconds, null, canChangeTracks);
}

function computeEntireTimeRemaining(time) {
  let seconds = audioStore?.activePlaylistTimeLeft + audioStore?.activeTrackCurrentTime - time;
  let runtimeH = Math.floor(seconds / 60 / 60);
  let runtimeM = Math.floor(seconds / 60 - runtimeH * 60);
  let runtimeS = Math.floor(seconds - runtimeH * 60 * 60 - runtimeM * 60);
  let label = '';
  if (runtimeH > 0) {
    label += `${runtimeH}${runtimeH !== 1 ? 'h' : 'h'}`;
  }
  if (runtimeM > 0) {
    label += ` ${runtimeM}${runtimeM !== 1 ? 'm' : 'm'}`;
  }

  return label ? `${label} left` : '';
}


// Track Progress -------------------------------------
const trackProgress = ref(0);
const externalChange = ref(false);
const lastPlayed = ref([null, 0, 0, 1]);

useAudioPluginListener({
  onProgressChange: ({ trackId, currentTime, status, playbackRate }) => {
    if (trackId !== audioStore.activeTrackId) return;
    externalChange.value = true;
    trackProgress.value = currentTime;
    nextTick(() => {
      externalChange.value = false;
    });
    lastPlayed.value = [trackId, currentTime, status, playbackRate];
  },
});

// const startTime = ref(0);
// const startTrackId = ref(0);
// const endTime = ref(0);
// const lastUpdateTime = ref(0);
// watch(
//   lastPlayed,
//   (
//     [newTrackId, newCurrentTime, newStatus, newPlaybackRate],
//     [lastTrackId, lastCurrentTime, lastStatus, lastPlaybackRate],
//   ) => {
//     if (newStatus === 'playing') {
//       if (lastStatus !== 'playing') {
//         startTime.value = newCurrentTime;
//         startTrackId.value = newTrackId;
//       }
//       if (
//         lastTrackId !== newTrackId ||
//         (lastCurrentTime !== newCurrentTime && newCurrentTime > lastUpdateTime.value + 3)
//       ) {
//         lastUpdateTime.value = newCurrentTime;
//         audioStore?.storeLastPlayed(newTrackId, newCurrentTime, newPlaybackRate);
//       }
//     } else if (lastStatus === 'playing' && newStatus !== 'playing') {
//       endTime.value = lastCurrentTime;
//       if (startTime.value < endTime.value && startTrackId.value === lastTrackId) {
//         audioStore?.storeSecondsPlayed(lastTrackId, endTime.value - startTime.value);
//       }
//     } else if (lastPlaybackRate !== newPlaybackRate) {
//       audioStore?.storeLastPlayed(newTrackId, newCurrentTime, newPlaybackRate);
//     }
//   },
// );


// Playback Rate -------------------------------------
const playbackRate = ref(1.001);
watch(() => audioStore.activeTrackPlaybackRate, (newRate) => {
  playbackRate.value = newRate || 1;
});

const formattedRate = computed(() => {
  const rate = audioStore.activeTrackPlaybackRate || playbackRate.value || 1;
  const formatted = (Math.round(rate * 100) / 100).toFixed(2);
  return formatted;
});

function changeRate(rate = null, closeOverlay = false) {
  if (rate === null) rate = playbackRate.value;
  audioStore?.changeRate(rate);
  if (closeOverlay) toggleOverlay(false);
}


// View Chapters -------------------------------------
async function scrollToChapters() {
  showLargeModal.value = false;
  // First make sure to navigate to the correct route
  const team = audioStore.activePlaylist.team.subdomain;
  const promo = audioStore.activePlaylist.slug;

  if (router.currentRoute?.name !== 'player-page' || router.currentRoute?.params?.team !== team || router.currentRoute?.params?.promo !== promo) {
    router.push({ name: 'player-page', params: { team, promo }})
    // sleep for 1 second to allow the route to change
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  // Scroll into view the element with the ID of chapter_list
  const chapterList = document.getElementById('chapter_list');
  console.log('chapterList', chapterList)
  if (chapterList) {
    chapterList.scrollIntoView({ behavior: 'smooth' });
  }
}


// Sleep Timer -------------------------------------
const sleepMinutes = ref(30);
const sleepToChapterEnd = computed(() => sleepMinutes.value === 65);

function changeSleep(minutes, closeOverlay = false) {
  if (closeOverlay) toggleOverlay(false);
  audioStore?.changeSleep(minutes);
}


// Audio Route Picker -------------------------------------

async function openAudioRoutePicker() {
  await Playback.openAudioRoutePicker();
}


// Mounted Events -------------------------------------
const forceClose = ref(false);
async function handleCloseAll() {
  forceClose.value = true;
  showLargeModal.value = false;
  showCarModeModal.value = false;
  audioStore.activeTrackId = null;
  await audioStore.pauseAllAudio();
  nextTick(() => {
    forceClose.value = false;
  });
}

const route = useRoute();
const promoId = ref(route.params?.promo);
watch(() => route.params?.promo, (newVal) => {
  if (newVal && newVal !== promoId.value) {
    handleCloseAll();
    promoId.value = newVal;
  }
});

// TODO: Open modal if there's a track ready to play

onMounted(() => {
  window.addEventListener('openPlayerGesture', async () => {
    console.log('openPlayerGesture');
  });
  window.addEventListener('closePlayerGesture', async () => {
    console.log('closePlayerGesture');
    // handleCloseAll();
  });
});

onUnmounted(() => {
  window.removeEventListener('openPlayerGesture');
  window.removeEventListener('closePlayerGesture');
});


// Expiry
const hasExpired = computed(() => getExpiry(audioStore.activePlaylist)?.hasExpired?.value);
watch(hasExpired, async (newVal, oldVal) => {
  if(newVal && !oldVal) {
    handleCloseAll();
  }
});

const percentBookCompleted = computed(() => {
  const left = audioStore.activePlaylistTimeLeft || 0;
  const total = audioStore.activePlaylistDuration || 1;
  return (total - left) / total * 100;
});
</script>

<template>
  <div class="AudioPlayerControlBar">
    <ion-modal id="modal" v-if="!forceClose" class="mx-gr-5 top-full h-gr-9 select-none dark:no-change ion-disable-focus-trap" ref="modal" style="
        --background: rgb(var(--color-primary-900)); 
        --max-height: var(--golden-ratio-8);
        --min-height: var(--golden-ratio-8);
        --border-radius: 7px;
      " :is-open="modalIsOpen" :show-backdrop="false" :can-dismiss="false">
      <div class="pl-gr-half-5 md:pl-gr-5 text-white flex items-center justify-between h-full relative shadow-xl" @click="showLargeModal = true">
        <div class="absolute bottom-0 inset-x-gr-half-5 md:inset-x-gr-5 h-gr-half-3">
          <div class="absolute bg-dark/50 dark:bg-primary-300/30 inset-0 bottom-0 h-gr-half-3"></div>
          <div class="absolute bg-secondary-300 dark:bg-light/70 left-0 bottom-0 h-gr-half-3" :style="{width: `${percentBookCompleted}%`}"></div>
        </div>
        <div class="flex items-center justify-center">
          <div class="aspect-square w-gr-7 rounded-sm overflow-hidden bg-light/50">
            <ion-img :src="cachedCover" class="h-full" />
          </div>
          <div class="self-stretch m-gr-4 text-sm mx-gr-4 max-w-[calc(90vw-150px)]">
            <div class="font-bold truncate">
              {{ audioStore?.activeTrack?.name }}
            </div>
            <div v-if="!audioStore.formattedPlaylistTimeLeft" class="font-bold truncate opacity-60 text-left">
              {{ audioStore.activeTrack?.name }}
              <span v-if="audioStore.activeTrack?.title">- {{ audioStore.activeTrack?.title }}</span>
            </div>
            <div class="pointer-events-none max-w-[calc(90vw-200px)]">
              <div class="opacity-60 font-bold truncate">
                {{ lockScrub ? totalRemainingTime : audioStore.formattedPlaylistTimeLeft }}
              </div>
            </div>
          </div>
        </div>
        <!-- Player Controls -->
        <div class="flex items-center justify-center shrink-0 ">
          <button v-if="isIos" @click.stop="openAudioRoutePicker" class="btn px-gr-3 py-gr-half-5 focus:ring-0 opacity-50 duration-200 hover:opacity-100 active:bg-white/10">
            <ion-icon :src="$icon('speaker')" class="fa-xl" />
          </button>
          <!-- <button v-else @click.stop="scrub(-30, true)" class="btn px-gr-3 py-gr-half-5 focus:ring-0 opacity-50 duration-200 hover:opacity-100 active:bg-white/10">
            <ion-icon :src="$icon('arrow-rotate-left')" class="fa-fw fa-lg" />
          </button> -->
          <button @click.stop="audioStore.playPauseTrack()" class="btn p-gr-half-5 md:p-gr-5 transition-none focus:ring-0">
            <ion-icon v-if="showLoadingIcon" :src="$icon('spinner-third')" class="fa-fw fa-xl fa-spin text-secondary-200" />
            <ion-icon v-else-if="showPauseIcon" :src="$icon('circle-pause')" class="fa-fw fa-xl text-secondary-200" />
            <ion-icon v-else-if="showPlayIcon" :src="$icon('circle-play')" class="fa-fw fa-xl text-secondary-200" />
          </button>
        </div>
      </div>
    </ion-modal>

    <!-- Large Modal -->
    <ion-modal id="full" class="select-none !top-0 text-primary-200 dark:no-change" :is-open="showLargeModal"
      @didDismiss="showLargeModal = false" :breakpoints="[0, 1]" :initial-breakpoint="1" :can-dismiss="true"
      :show-backdrop="true" :keep-contents-mounted="false">
      <div class="bg-gradient-to-br from-primary-dark to-primary-darker absolute inset-0 z-[1] pointer-events-none"></div>
      <div class="mix-blend-overlay opacity-80 absolute z-10 w-full h-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-cover bg-center pointer-events-none"
        :style="{ backgroundImage: 'url(' + blurredCover + ')' }"></div>

      <div class="relative flex flex-col justify-between z-20 w-full pt-gr-6 h-full">
        <div class="w-grf-double-11 max-w-grp-double-4 mx-auto">
          <p class="text-white font-secondary font-bold text-xl-lead line-clamp-1">{{ audioStore.activePlaylist.book.title }}</p>
          <p class="font-semibold line-clamp-1">{{ audioStore.activePlaylist.book.author_list }}</p>
        </div>

        <div class="mt-gr-3 text-center pointer-events-none aspect-square rounded-lg overflow-hidden bg-light/30 w-grf-double-11 max-w-grp-double-4 mx-auto">
          <ion-img :src="cachedCover" class="h-full" />
        </div>

        <div class="text-white">
          <div class="self-stretch m-gr-4 mx-gr-6">
            <div class="font-bold truncate text-center text-lg">
              {{ audioStore.activeTrack?.name }}
              <span v-if="audioStore.activeTrack?.title">- {{ audioStore.activeTrack?.title }}</span>
            </div>
            <div class="h-gr-7 py-gr-6">
              <div class="relative">
                <ion-range
                  class="px-0 absolute inset-x-0 top-1/2 -translate-y-1/2"
                  :value="lockScrub ? tempTime : audioStore.activeTrackCurrentTime" step="0.1" :min="0"
                  :max="audioStore.activeTrackDuration" mode="md" color="tertiary" @ionKnobMoveEnd="rangeChange"
                  @ionKnobMoveStart="lockScrub = true" @ionChange="scrubDrag" />
              </div>
            </div>
            <div class="pointer-events-none flex items-center justify-between -mt-gr-half-6">
              <div class="text-xs font-bold -translate-x-1/2">
                {{ lockScrub ? convertTimeHHMMSS(tempTime) : audioStore.formattedCurrentTime }}
              </div>
              <div class="opacity-60 font-black text-xs">
                {{ lockScrub ? totalRemainingTime : audioStore.formattedPlaylistTimeLeft }}
              </div>
              <div class="text-xs font-bold translate-x-1/2">
                {{ lockScrub ? `-${convertTimeHHMMSS(audioStore.activeTrackDuration - tempTime)}` :
                  audioStore.formattedTrackTimeLeft }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-grf-3 text-white">
          <!-- Player Controls -->
          <div class="flex items-center justify-center shrink-0">
            <!-- Skip back -->
            <button @click="audioStore.skipTo(-1)" class="btn p-grf-4 xs:p-grf-5">
              <ion-icon :src="$icon('arrow-left-to-line')" class="fa-fw fa-lg" />
            </button>
            <!-- Scrub back -->
            <button @click="scrub(-30, true)" class="relative p-grf-4 xs:p-grf-5">
              <span class="translate-y-grf-3 -mr-grf-3 text-xs font-semibold">30</span>
              <span class="p-0 btn w-grf-7 h-grf-7">
                <ion-icon :src="$icon('arrow-rotate-left')" class="fa-fw fa-lg" />
              </span>
            </button>
            <!-- Play/Pause -->
            <button @click="audioStore.playPauseTrack()"
              class="btn p-grf-4 xs:p-grf-5 transition-none scale-150 mx-grf-4 xs:mx-grf-5 text-secondary">
              <ion-icon v-if="showLoadingIcon" :src="$icon('spinner-third')"
                class="fa-fw fa-2x fa-spin text-secondary-200" />
              <ion-icon v-else-if="showPauseIcon" :src="$icon('circle-pause')" class="fa-fw fa-2x text-secondary-200" />
              <ion-icon v-else-if="showPlayIcon" :src="$icon('circle-play')" class="fa-fw fa-2x text-secondary-200" />
            </button>
            <!-- Scrub forward -->
            <button @click="scrub(30)" class="relative p-grf-4 xs:p-grf-5">
              <span class="p-0 btn w-grf-7 h-grf-7">
                <ion-icon :src="$icon('arrow-rotate-right')" class="fa-fw fa-lg" />
              </span>
              <span class="translate-y-grf-3 -ml-grf-3 text-xs normal-case font-semibold">30</span>
            </button>
            <!-- Skip forward -->
            <button @click="audioStore.skipTo(1)" class="btn p-grf-4 xs:p-grf-5" :disabled="!audioStore.hasNextTrack">
              <ion-icon :src="$icon('arrow-right-to-line')" class="fa-fw fa-lg" />
            </button>
          </div>

        </div>

        <!-- Extra Controls -->
        <div class="mt-gr-5 text-white">
          <div class="grid mb-gr-7 mx-gr-4" :class="isMobile ? 'grid-cols-4' : 'grid-cols-3'">
            <button @click="toggleOverlay('rate')"
              class="inline-flex items-center justify-center w-full leading-[1] pt-gr-2 pb-gr-3 flex-col text-xs font-semibold">
              <span class="h-gr-6 flex items-end">
                <div class="text-xl leading-[1]">{{ formattedRate }}</div>
                <ion-icon :src="$icon('xmark')" class="text-sm" />
              </span>
              <span class="mt-gr-2">Speed</span>
            </button>
            <button @click.stop="scrollToChapters"
              class="inline-flex items-center justify-center w-full leading-[1] pt-gr-2 pb-gr-3 flex-col text-xs font-semibold">
              <ion-icon :src="$icon('list')" class="h-gr-6 fa-fw fa-2x" />
              <span class="mt-gr-2">Chapters</span>
            </button>
            <!-- <button v-if="isIos" @click="openAudioRoutePicker"
              class="inline-flex items-center justify-center w-full leading-[1] pt-gr-2 pb-gr-3 flex-col text-xs font-semibold">
              <span>
                <ion-icon :src="$icon('speaker')" class="h-gr-6 fa-fw fa-2x" />
              </span>
              <span class="mt-gr-2">Audio</span>
            </button> -->
            <button @click="toggleOverlay('sleep')"
              class="inline-flex items-center justify-center w-full leading-[1] pt-gr-2 pb-gr-3 flex-col text-xs font-semibold">
              <span :class="audioStore?.sleepTimeEnabled ? 'text-secondary-400' : ''">
                <ion-icon :src="$icon('alarm-snooze')" class="h-gr-6 fa-fw fa-2x" />
              </span>
              <span class="mt-gr-2">Timer</span>
            </button>
            <button v-if="isMobile" @click="showCarModeModal = true"
              class="inline-flex items-center justify-center w-full leading-[1] pt-gr-2 pb-gr-3 flex-col text-xs font-semibold">
              <span>
                <ion-icon :src="$icon('car-side')" class="h-gr-6 fa-fw fa-2x" />
              </span>
              <span class="mt-gr-2">Car Mode</span>
            </button>
          </div>
        </div>
      </div>
    </ion-modal>

    <!-- Car Modal -->
    <ion-modal id="carmode" class="select-none !top-0" :is-open="showCarModeModal"
      @didDismiss="showCarModeModal = false" :breakpoints="[0, 1]" :initial-breakpoint="1" :can-dismiss="true"
      :show-backdrop="true" style="--background: rgb(var(--color-primary-50))" :keep-contents-mounted="false">
      <div class="h-full w-full flex flex-col justify-between items-center pt-gr-6">
        <button @click="showCarModeModal = false" class="btn w-full py-gr-6 mt-safe dark:text-white">
          Exit Drive Mode
        </button>
        <div class="shrink pointer-events-none aspect-square overflow-hidden rounded-lg bg-light/50 w-gr-double-11 max-w-grp-double-4 mx-auto">
          <ion-img :src="cachedCover" class="h-full" />
        </div>
        <div class="py-grf-half-5 text-center px-grf-6 line-clamp-2 text-grf-double-5">{{ audioStore.activeTrack?.name }}</div>
        <div class="grow w-full flex flex-col">
          <button @click="audioStore.playPauseTrack()" class="grow w-full py-gr-6 text-4xl">
            <ion-icon v-if="showLoadingIcon" :src="$icon('spinner-third')" class="fa-fw fa-2x fa-spin dark:text-secondary-dark" />
            <ion-icon v-else-if="showPauseIcon" :src="$icon('circle-pause')" class="fa-fw fa-2x dark:text-secondary-dark" />
            <ion-icon v-else-if="showPlayIcon" :src="$icon('circle-play')" class="fa-fw fa-2x dark:text-secondary-dark" />
          </button>
          <div class="grow relative w-full">
            <ion-range
              class="px-0 absolute z-10 inset-x-0 bottom-full translate-y-1/2"
              :value="lockScrub ? tempTime : audioStore.activeTrackCurrentTime" step="0.1" :min="0"
              :max="audioStore.activeTrackDuration" mode="md" color="tertiary"
              style="--knob-size:0px; --bar-height:5px" />
            <div class="h-full relative z-20 flex items-stretch justify-between divide-x-[1px] divide-primary-200/60 dark:text-white">
              <button @click="scrub(-30, true)"
                class="w-1/2 flex items-center justify-center py-gr-6 rounded-none font-semibold">
                <span class="translate-y-gr-3">30s</span>
                <ion-icon :src="$icon('arrow-rotate-left')" class="fa-fw fa-2x" />
              </button>
              <button @click="scrub(30)"
                class="w-1/2 flex items-center justify-center py-gr-6 rounded-none font-semibold">
                <ion-icon :src="$icon('arrow-rotate-right')" class="fa-fw fa-2x" />
                <span class="translate-y-gr-3">30s</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>

    <!-- Overlay Modal -->
    <ion-modal class="select-none" :is-open="showOverlay" style="
      --width: fit-content;
      --height: fit-content;
      --backdrop-opacity: 0.8;
    " :show-backdrop="true" @didDismiss="toggleOverlay(false)">
      <div class="text-center p-gr-5 text-gray-800 bg-light rounded-lg w-gr-double-11 mx-auto max-w-grp-double-4">
        <div class="relative">
          <strong v-if="showRateOverlay">Narration Speed</strong>
          <strong v-if="showSleepOverlay">Sleep Timer</strong>
          <button @click.stop="toggleOverlay(false)" class="absolute -right-gr-5 -top-gr-5 btn p-gr-5 opacity-40">
            <ion-icon :src="$icon('xmark')" class="fa-fw fa-lg" />
          </button>
        </div>

        <!-- Change Rate -->
        <template v-if="showRateOverlay">
          <div class="mt-gr-6 font-bold">
            {{ formattedRate }}
          </div>
          <div class="flex items-center justify-between">
            <ion-range v-model="playbackRate" step=".25" min="0.50"
              max="3.00" mode="md" color="primary" @ionKnobMoveEnd="changeRate(playbackRate)">
              <ion-label slot="start"><strong>0.50x</strong></ion-label>
              <ion-label slot="end"><strong>3.0x</strong></ion-label>
            </ion-range>
          </div>
          <button @click="toggleOverlay(false)" class="mt-gr-4 btn btn-primary w-full">
            Ok
          </button>
          <button @click="changeRate(1, true)" class="mt-gr-4 btn w-full">Reset</button>
        </template>

        <!-- Sleep Timer -->
        <template v-if="showSleepOverlay">
          <div class="mt-gr-6 font-bold">
            {{
              sleepMinutes === 0
              ? `No Sleep Timer`
              : sleepToChapterEnd
                ? 'Until chapter ends'
                : `${sleepMinutes} minute${sleepMinutes === 1 ? '' : 's'}`
            }}
          </div>
          <ion-range v-model="sleepMinutes" step="1" min="0" max="65" mode="md" color="primary">
            <ion-label slot="start"><strong>Off</strong></ion-label>
            <ion-label slot="end"><strong>Chapter End</strong></ion-label>
          </ion-range>
          <button @click="changeSleep(sleepMinutes, true)" class="mt-gr-4 btn btn-primary w-full">Ok</button>
        </template>
      </div>
    </ion-modal>
  </div>
</template>

<style scoped>
#modal {
    --safe-bottom: env(safe-area-inset-bottom);
    transform: translateY(calc((100% + var(--safe-bottom) + var(--golden-ratio-double-5)) * -1));
}
#modal,
#modal::part(backdrop) {
  @apply pointer-events-none;
}
#modal::part(content) {
  @apply pointer-events-auto;
}

#carmode ion-range::part(bar) {
  background-color: rgb(var(--color-primary-200) / .5);
}
#full ion-range::part(bar) {
  background-color: rgb(var(--color-primary-200) / .2);
}
#full ion-range::part(knob),
#full ion-range::part(bar-active) {
  background-color: rgb(var(--color-tertiary-500));
}
</style>