import { convertTimeHHMMSS } from '@/utils/date';
import { defineStore } from 'pinia';
import { Device } from '@capacitor/device';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { isPlatform } from '@ionic/vue';
import { isRef } from '@vue/reactivity';
import { Network } from '@capacitor/network';
import { Playback } from 'playback';
import { Preferences } from '@capacitor/preferences';
import { queryDB, runDB } from '@/utils/sqlite';
import { ref, computed, nextTick } from 'vue';
import api from '@/utils/api';

const noop = (fallback = null) => fallback;
let autoplayCallback = noop;
let showLoadingTimeout;

export const useAudioStore = defineStore('audio', {
  state: () => ({
    autoplayEnabled: true,
    sortTracksBy: 'order',
    sortTracksAsc: true,
    playlists: [],
    activeTrackId: null,
    lastPlayedById: {},
    downloadProgressByTrackId: {},
    playlistDownloadRequestById: {},
    sleepTimeout: null,
    trackEndTimeout: null,
    sleepUntilTrackEnd: false,
    statusByTrackId: {},
    currentTimeByTrackId: {},
    durationByTrackId: {},
    bufferedByTrackId: {},
    rateByTrackId: {},
    hasEndedByTrackId: {},
    isFetchingLastPlayed: false,
    hasFetchedLastPlayed: false,
    lastPlayedTrackId: null,
    queueForLastPlayed: [],
    preventPlayback: true,
    playbackRate: 1,
    showLargePlaybackModal: false,
    showLoading: false,
  }),

  getters: {
    lastPlayed: (state) =>
      state.lastPlayedById[state.activePlaylistId]?.length
        ? state.lastPlayedById[state.activePlaylistId][0]
        : null,

    playingTrackIds: (state) =>
      Object.keys(state.statusByTrackId).filter(
        (trackId) => state.statusByTrackId[trackId] === 'playing',
      ),

    playlistIds: (state) => state.playlists.map((playlist) => playlist.id),

    indicesByTrackId: (state) =>
      state.playlists.reduce((obj, playlist, playlistIndex) => {
        playlist.tracks.forEach((track, trackIndex) => {
          obj[track.id] = [playlistIndex, trackIndex];
        });
        return obj;
      }, {}),

    playlistIdByTrackId: (state) =>
      state.tracks.reduce((obj, track) => {
        if (track?.id) obj[track.id] = track.playlistId;
        return obj;
      }, {}),

    playlistsById: (state) =>
      state.playlists.reduce((obj, playlist) => {
        obj[playlist.id] = playlist;
        return obj;
      }, {}),

    playlistsBySlug: (state) =>
      state.playlists.reduce((obj, playlist) => {
        obj[playlist.slug] = playlist;
        return obj;
      }, {}),

    tracks: (state) => state.playlists.reduce((arr, playlist) => arr.concat(playlist.tracks), []),

    tracksById: (state) =>
      state.tracks.reduce((obj, track) => {
        obj[track.id] = track;
        return obj;
      }, {}) || {},

    sortedTracks: (state) => {
      const tracks = [...state.tracks];
      tracks.sort((a, b) => {
        if (a[state.sortTracksBy] < b[state.sortTracksBy]) return state.sortTracksAsc ? -1 : 1;
        if (a[state.sortTracksBy] > b[state.sortTracksBy]) return state.sortTracksAsc ? 1 : -1;
        return 0;
      });
      return tracks;
    },

    sortedTracksByPlaylistId: (state) =>
      state.sortedTracks.reduce((obj, track) => {
        if (obj[track.playlistId]) obj[track.playlistId].push(track);
        else obj[track.playlistId] = [track];
        return obj;
      }, {}),

    sortedTrackIdsByPlaylistId: (state) =>
      state.playlistIds.reduce((obj, playlistId) => {
        obj[playlistId] =
          state.sortedTracksByPlaylistId[playlistId]?.map((track) => track.id) || [];
        return obj;
      }, {}),

    activeTrack: (state) => state.tracksById[state.activeTrackId] || null,
    activeTrackIds: (state) => state.sortedTrackIdsByPlaylistId[state.activePlaylistId] || [],
    activeTrackIndex: (state) => state.activeTrackIds?.indexOf(state.activeTrackId),
    activeTrackBuffered: (state) => state.bufferedByTrackId[state.activeTrackId] || 0,
    activeTrackPlaybackRate: (state) => {
      return state.playbackRate;
    },
    activeTrackCurrentTime: (state) => state.currentTimeByTrackId[state.activeTrackId] || 0,
    activeTrackStatus: (state) => state.statusByTrackId[state.activeTrackId],
    activeTrackDuration: (state) =>
      state.activeTrack?.duration || state.durationByTrackId[state.activeTrackId] || 1,
    activeTrackIsPlaying: (state) => state.activeTrackStatus === 'playing' || false,
    activeTrackIsLoading: (state) => state.activeTrackStatus === 'loading' || false,
    activeTrackIsErrored: (state) => state.activeTrackStatus === 'error' || false,
    activeTrackIsPaused: (state) => state.activeTrackStatus === 'paused' || false,
    activeTrackHasEnded: (state) => state.hasEndedByTrackId[state.activeTrackId] || false,
    activeTracks: (state) => state.sortedTracksByPlaylistId[state.activePlaylistId] || [],
    activePlaylistId: (state) => state.activeTrack?.playlistId || null,
    activePlaylist: (state) => state.playlistsById[state.activePlaylistId] || null,
    activePlaylistTimeLeft: (state) => {
      if (isNaN(parseInt(state.activeTrackIndex, 10))) return 0;
      if (state.activePlaylist?.isLoading) return 0;
      // if (Object.values(state.durationByTrackId).find((dur) => dur === 1)) return 0;
      return (
        state.activeTrackTimeLeft +
        state.activeTracks
          .slice(state.activeTrackIndex + 1)
          .reduce(
            (timeLeft, track) => timeLeft + (track?.duration || state.durationByTrackId[track.id]),
            0,
          )
      );
    },
    activePlaylistDuration: (state) => {
      if (state.activePlaylist?.isLoading) return 0;
      return state.activeTracks.reduce(
        (duration, track) => duration + (track?.duration || state.durationByTrackId[track.id]),
        0,
      );
    },
    activeTrackTimeLeft: (state) => state.activeTrackDuration - state.activeTrackCurrentTime,
    activeTrackProgress: (state) =>
      Math.min(100, Math.ceil((state.activeTrackCurrentTime / state.activeTrackDuration) * 100)),
    formattedCurrentTime: (state) => convertTimeHHMMSS(state.activeTrackCurrentTime),
    formattedDuration: (state) => convertTimeHHMMSS(state.activeTrackDuration),
    formattedTrackTimeLeft: (state) => `-${convertTimeHHMMSS(state.activeTrackTimeLeft)}`,
    formattedPlaylistTimeLeft: (state) => {
      let seconds = state.activePlaylistTimeLeft;
      let runtimeH = Math.floor(seconds / 60 / 60);
      let runtimeM = Math.floor(seconds / 60 - runtimeH * 60);
      let runtimeS = Math.floor(seconds - runtimeH * 60 * 60 - runtimeM * 60);
      let label = '';
      if (runtimeH > 0) {
        label += `${runtimeH}${runtimeH !== 1 ? 'h' : 'h'}`;
      }
      if (runtimeM > 0) {
        label += ` ${runtimeM}${runtimeM !== 1 ? 'm' : 'm'}`;
      }

      return label ? `${label} left` : '';
    },

    hasNextTrack: (state) => state.activeTrackIndex !== state.activeTracks.length - 1,
    hasPrevTrack: (state) => state.activeTrackIndex !== 0,
    sleepTimeEnabled: (state) => state.sleepUntilTrackEnd || state.sleepTimeout !== null,
  },

  actions: {
    setLargePlaybackModal(val) {
      this.showLargePlaybackModal = val;
      return;
    },
    async preparePlaylist(playlist) {
      if (this.playlistIds.includes(playlist.id)) return;
      let tracks = await Promise.all(playlist.tracks.map(async (track, index) => {
        if (track?.duration) this.durationByTrackId[track.id] = track.duration;

        const path = `${playlist.slug}/${track.id}.${track.ext}`;
        let audioTrack = {
          id: track.id,
          trackId: track.id,
          trackIndex: index,
          path,
          audioUrl: localStorage.getItem(path) || track.track,
          title: `${track.name}${track.title ? ' - ' + track.title : ''}`,
          artist: playlist?.book?.author_list,
          albumTitle: playlist?.book?.title,
          coverUrl: `${playlist?.book?.cover}?w=512&h=512&fm=png`,
          audioItem: null,
          extension: track.ext,
          teamId: playlist.team.id,
          bookId: playlist.book.id,
          promoId: playlist.id,
          teamSlug: playlist.team.subdomain,
          promoSlug: playlist.slug,
          duration: track.duration
        };

        await Playback.prepareTrack(audioTrack).then(({ audioItem }) => {
          this.setTrackAudioItem(track.id, audioItem);
        });

        return {
          ...track,
          playlistId: playlist.id,
          isPlayable: ref(!!track.track),
          isLoading: ref(false),
          isReady: ref(false),
          audioTrack,
        };
      }));

      let augmentedPlaylist = {
        ...playlist,
        tracks,
        isLoading: computed(() => tracks.some((track) => track.isLoading.value)),
        isReady: computed(() => tracks.some((track) => track.isReady.value)),
        isPrepared: ref(true),
      };

      this.playlists.push(augmentedPlaylist);

      return augmentedPlaylist;
    },
    async checkIfDownloadedTrack(trackId, playlist) {
      // if (isPlatform('capacitor')) {
      let track = playlist.tracks.find(track => track.id === trackId);
      let stat = {};
      try {
        if (this.downloadProgressByTrackId[trackId] === 100) {
          stat = { status: 'fulfilled' };
          return stat;
        }
        stat = await Filesystem.stat({
          path: `${track.id}.${track.ext}`,
          directory: Directory.Library,
        });
        if (stat) {
          stat = { ...stat, status: 'fulfilled' };
        }
      } catch (e) { }
      return stat;
      //}
    },
    async checkIfDownloaded(playlist) {
      if (isPlatform('capacitor')) {

        const sqlite = window.sqlite;
        const isConn = (await sqlite.isConnection("db", false)).result;
        let db;
        const ret = await sqlite.checkConnectionsConsistency();
        if (ret.result && isConn) {
          db = await sqlite.retrieveConnection("db", false);
        } else {
          db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
        }

        let downloadedFiles = (await db.query(`SELECT * FROM downloaded_files WHERE book_id = ${playlist.book.id}`)).values
        // alert(downloadedFiles.length)

        let res = {};
        for (let row of downloadedFiles) {
          //const track = playlist.tracks[index];
          res[row.track_id] = { status: 'fullfilled' };
          this.downloadProgressByTrackId[row.track_id] = 100;
        }
        //return res

        // let statPromises = playlist.tracks.map((track) =>
        //   Filesystem.stat({
        //     path: `${track.id}.${track.ext}`,
        //     directory: Directory.Library,
        //   }),
        // );
        // const results = await Promise.allSettled(statPromises);
        // let r = results.reduce((obj, stat, index) => {
        //   const track = playlist.tracks[index];
        //   obj[track.id] = stat;
        //   return obj;
        // }, {});
        //  alert(`${playlist.book.id} down: ${Object.keys(res).length} total: ${playlist.tracks.length}`)
        return res;

      }

      return {};
      try {
        let statPromises = playlist.tracks.map((track) =>
          Filesystem.stat({
            path: `${playlist.slug}/${track.id}.${track.ext}`,
            directory: Directory.Library,
          }),
        );
        const results = await Promise.allSettled(statPromises);
        return results.reduce((obj, stat, index) => {
          const track = playlist.tracks[index];
          obj[track.id] = stat;
          return obj;
        }, {});
      } catch (error) {
        console.error(error);
        return playlist.tracks.reduce((obj, track) => {
          obj[track.id] = { status: 'error', value: error };
          return obj;
        }, {});
      }
    },

    async loadTrack(trackId, loadMore = 0) {
      let track = this.tracksById[trackId];
      const isReady = isRef(track?.isReady) ? !!track?.isReady?.value : !!track?.isReady;

      if (isPlatform('capacitor')) {
        console.log('TODO: ios loadTrack', trackId);
        return;
      }
      // console.log('---loadTrack', trackId, loadMore);
      const loadMoreTracks = () => {
        // Maybe load more tracks
        if (loadMore > 0) {
          const [playlistIndex, trackIndex] = this.indicesByTrackId[trackId] || [-1, -1];
          if (playlistIndex > -1 && trackIndex > -1) {
            if (trackIndex < this.playlists[playlistIndex].tracks.length - 1) {
              const nextTrack = this.playlists[playlistIndex].tracks[trackIndex + 1];
              if (nextTrack) {
                const delay = isPlatform('ios') ? 500 : 0;
                setTimeout(() => {
                  this.loadTrack(nextTrack.id, loadMore - 1);
                }, delay);
              }
            }
          }
        }
      };

      if (isReady) {
        loadMoreTracks();
        return loadMore;
      }

      this.setTrackIsLoading(trackId, true);
      const loadedTrack = await Playback.loadTrack({ audioTrack: { ...track.audioTrack } });
      this.setTrackIsLoading(trackId, false);

      loadMoreTracks();

      if (loadedTrack?.progress) this.updateProgress(loadedTrack.progress);
      if (loadedTrack?.audioItem && loadedTrack?.isPlayable !== undefined) {
        this.setTrackIsPlayable(trackId, loadedTrack.isPlayable);
        this.setTrackIsReady(trackId, true);
        this.setTrackAudioItem(trackId, loadedTrack.audioItem);
        return loadedTrack.isPlayable;
      }

      this.statusByTrackId[trackId] = 'error';
      throw new Error('Cannot load track');
    },

    setTrackIsLoading(trackId, isLoading) {
      const [playlistIndex, trackIndex] = this.indicesByTrackId[trackId] || [-1, -1];
      if (playlistIndex > -1 && trackIndex > -1)
        this.playlists[playlistIndex].tracks[trackIndex].isLoading = isLoading;
    },

    setTrackIsPlayable(trackId, isPlayable) {
      const [playlistIndex, trackIndex] = this.indicesByTrackId[trackId] || [-1, -1];
      if (playlistIndex > -1 && trackIndex > -1)
        this.playlists[playlistIndex].tracks[trackIndex].isPlayable = isPlayable;
    },

    setTrackIsReady(trackId, isReady) {
      const [playlistIndex, trackIndex] = this.indicesByTrackId[trackId] || [-1, -1];
      if (playlistIndex > -1 && trackIndex > -1)
        this.playlists[playlistIndex].tracks[trackIndex].isReady = isReady;
    },

    setTrackAudioItem(trackId, audioItem) {
      const [playlistIndex, trackIndex] = this.indicesByTrackId[trackId] || [-1, -1];
      if (playlistIndex > -1 && trackIndex > -1)
        this.playlists[playlistIndex].tracks[trackIndex].audioTrack.audioItem = audioItem;
    },

    setTrackPath(trackId, path) {
      const [playlistIndex, trackIndex] = this.indicesByTrackId[trackId] || [-1, -1];
      if (playlistIndex > -1 && trackIndex > -1) {
        this.playlists[playlistIndex].tracks[trackIndex].audioTrack.path = path;
      }
    },

    setTrackAudioUrl(trackId, audioUrl) {
      // console.log('---setTrackAudioUrl', trackId, audioUrl);
      const [playlistIndex, trackIndex] = this.indicesByTrackId[trackId] || [-1, -1];
      if (playlistIndex > -1 && trackIndex > -1) {
        this.playlists[playlistIndex].tracks[trackIndex].audioTrack.audioUrl = audioUrl;
        if (!isPlatform('capacitor'))
          this.playlists[playlistIndex].tracks[trackIndex].audioTrack.audioItem.src = audioUrl;
        if (audioUrl) {
          this.setTrackIsPlayable(trackId, true);
          this.setTrackIsReady(trackId, true);
        }
      }
    },

    async checkTrackProgress(trackId) {
      let { audioTrack } = this.tracksById[trackId];
      const progress = await Playback.checkTrackProgress({ audioTrack: { ...audioTrack } });
      this.updateProgress(progress);
      return progress;
    },

    async playPausePlaylist(playlistId) {
      this.getDurations();
      console.log('TODO: playPausePlaylist()');
    },

    async playPauseTrack(trackId = null, showLargePlaybackModal = false, forcePlay = false) {
      if (trackId == null && (this.showLoading || this.activeTrackIsLoading)) {
        await this.pauseAllAudio();
        return;
      }

      // console.log('---playPauseTrack', trackId);
      await this.getDurations();
      if (trackId === null) trackId = this.activeTrackId;

      // If it's not active yet, play it
      if (trackId !== this.activeTrackId) {
        await this.setActiveTrack(trackId);
        const duration = this.durationByTrackId[trackId] || 1;
        let position = ((this.currentTimeByTrackId[trackId] || 0) / duration) * 100;
        if (this.hasEndedByTrackId[trackId] || this.currentTimeByTrackId[trackId] + 5 > this.durationByTrackId[trackId]) {
          position = 0;
        }
        await this.seekTo(position, trackId, true);

        // It's possible that the track needed to be loaded first and won't automatically play from the above method
        // So, let's try again just in case
        await new Promise((res) => setTimeout(res, 1000));
        let isPlaying = this.statusByTrackId[trackId] === 'playing';
        if (!isPlaying) {
          await this.play(trackId, true);
        }

        return;
      };

      const isPlaying = this.statusByTrackId[trackId] === 'playing';
      if (this.hasEndedByTrackId[trackId]) {
        // Prevent it from skipping to next track
        await this.seekTo(0, trackId, isPlaying);
      }
      if (forcePlay) {
        return await this.play(trackId, true);
      }
      // Just pause everything
      await this.pauseAllAudio();
      // After pause
      if (isPlaying) {
        return; //await this.scrub(Math.max(this.activeTrackCurrentTime - 3, 0), trackId, false);
      }
      // Ok, let's play it
      let play = await this.play(trackId, true);
      this.showLargePlaybackModal = showLargePlaybackModal;
      // alert(showLargePlaybackModal)
      return play;
    },

    async pauseAllAudio() {
      // console.log('---pauseAllAudio');
      let promises = this.playingTrackIds.map((trackId) => this.pause(trackId));
      if (promises.length) {
        return await Promise.allSettled(promises);
      }
      return new Promise((res) => res());
    },

    async pause(trackId = null, setActive = false) {
      // console.log('---pause', trackId, setActive);
      if (trackId === null) trackId = this.activeTrackId;
      if (setActive) await this.setActiveTrack(trackId);
      const { audioTrack } = this.tracksById[trackId];
      this.statusByTrackId[trackId] = 'paused';
      return await Playback.pause({ audioTrack: { ...audioTrack } });
    },

    async play(trackId = null, setActive = false) {
      // console.log('---play', trackId, setActive);
      if (trackId === null) trackId = this.activeTrackId;
      if (setActive) await this.setActiveTrack(trackId);
      const { audioTrack } = this.tracksById[trackId];
      this.statusByTrackId[trackId] = 'playing';
      this.lastPlayedTrackId = trackId;

      return await Playback.play({ audioTrack: { ...audioTrack }, rate: this.playbackRate * 100 });
    },

    async seekTo(position, trackId = null, autoplay = true) {
      // console.log('---seekTo', position, trackId);
      //await this.pauseAllAudio()
      position = Math.max(Math.min(position, 100), 0);
      if (trackId === null) trackId = this.activeTrackId;
      const { audioTrack } = this.tracksById[trackId];
      const progress = await Playback.seekTo({
        position: `${position}`,
        audioTrack: { ...audioTrack },
        autoplay: autoplay
      });

      if (progress.currentTime > 1)
        this.storeLastPlayed(trackId, progress.currentTime, progress.rate || null);
      return progress;
    },

    async scrub(seconds, trackId = null, canChangeTracks = false) {
      // console.log('---scrub', seconds, trackId, canChangeTracks);
      if (trackId === null) trackId = this.activeTrackId;
      const currentTime = this.currentTimeByTrackId[trackId] || 0;
      const duration = this.durationByTrackId[trackId] || 1;
      const trackIndex = this.activeTrackIds.indexOf(trackId);
      let position = (seconds / duration) * 100;
      // Min position should be 0, max should be 100
      position = Math.max(Math.min(position, 100), 0);
      const isPlaying = this.statusByTrackId[trackId] === 'playing';
      const seekRes = await this.seekTo(position, trackId, isPlaying);

      const isUnder = currentTime < 2;
      const isOver = duration - currentTime < 2;
      const shouldChangeTracks = canChangeTracks && (isUnder || isOver);
      if (shouldChangeTracks && isPlatform('desktop')) {
        let indexDiff = isOver ? 1 : -1;
        const newTrackId = this.activeTrackIds[trackIndex + indexDiff];
        if (newTrackId) {
          const newDuration = this.durationByTrackId[newTrackId] || 1;
          const newCurrentTime = newDuration - Math.abs(seconds);
          const newPosition = (newCurrentTime / newDuration) * 100;
          return await this.skipTo(indexDiff, isPlaying, true, newPosition);
        }
      }
      return seekRes;
    },

    async skipTo(indexDiff = 1, forcePlay = null, forceSkip = false, seekPosition = 0) {
      // console.log('---skipTo', indexDiff, forcePlay, forceSkip, seekPosition);
      const currentTime = this.currentTimeByTrackId[this.activeTrackId];
      const newIndex = this.activeTrackIndex + indexDiff;
      let newTrackId = this.activeTrackIds[newIndex];
      const shouldPlay = (this.activeTrackIsPlaying && forcePlay !== false) || forcePlay === true;
      if (this.activeTrackIsPlaying) await this.pause(this.activeTrackId);

      // Don't worry about skipping
      if (newIndex >= this.activeTracks.length) {
        return this.getProgress(this.activeTrackId);
      }

      // Don't skip, but do scrub
      if (newIndex < 0) {
        return await this.seekTo(0, this.activeTrackId, shouldPlay);
      }

      // If it had played some before, maybe go back to that position
      // let currentPosition =
      //   ((this.currentTimeByTrackId[newTrackId] || 0) / (this.durationByTrackId[newTrackId] || 1)) *
      //   100;

      // In this case, stay on the currently active track
      if (indexDiff < 0 && currentTime > 2 && !forceSkip) {
        newTrackId = this.activeTrackId;
        // Ignore whatever the current position was; set it to zero now
        seekPosition = 0;
      }
      await this.seekTo(seekPosition, newTrackId, shouldPlay);
      return shouldPlay ? await this.play(newTrackId, true) : await this.pause(newTrackId, true);
    },

    async changeRate(rate, trackId = null) {
      if (trackId === null) trackId = this.activeTrackId;
      // console.log('---changeRate', trackId, this.tracksById[trackId]);
      const { audioTrack } = this.tracksById[trackId];
      this.playbackRate = rate;
      await Playback.changeRate({ rate: rate * 100, audioTrack: { ...audioTrack } });
    },

    async changeSleep(minutes, trackId = null) {
      if (isPlatform('capacitor')) {
        if (minutes > 0 && minutes < 65) {
          await Playback.setSleepMode({
            secondsTilSleep: minutes * 60
          });
        } else if (minutes === 0) {
          await Playback.setSleepMode({
            secondsTilSleep: -1
          });
        } else if (minutes === 65) {
          await Playback.setSleepMode({
            stopChapterEnd: true
          });
        }
      } else {
        clearTimeout(this.sleepTimeout);
        if (trackId === null) trackId = this.activeTrackId;
        if (minutes > 0 && minutes < 65) { // Set sleep timer
          // Start playing the track
          await this.play(trackId);
          return new Promise((res) => {
            this.sleepTimeout = setTimeout(async () => {
              const pauseResults = await this.pause();
              this.sleepTimeout = null;
              return res(pauseResults);
            }, minutes * 60 * 1000);
          });
        } else if (minutes === 0) { // Cancel sleep timer
          this.sleepTimeout = null;
          return new Promise((res) => res());
        } else if (minutes === 65) { // Stop at chapter end
          // Start playing the track
          await this.play(trackId);
          this.sleepUntilTrackEnd = true;
          return new Promise((res) => res());
        }
      }
    },

    async onTrackEnded(trackId) {
      // console.log('---onTrackEnded', trackId, this.autoplayEnabled);
      let progress = this.getProgress(trackId);
      if (this.autoplayEnabled) {
        const rate = this.activeTrackPlaybackRate;
        const thenPlay = !this.sleepUntilTrackEnd;
        progress = await this.skipTo(1, thenPlay);
        // await this.changeRate(rate);
        autoplayCallback(trackId);
        autoplayCallback = noop;
      }
      this.sleepUntilTrackEnd = false;
      return progress;
    },

    async setActiveTrack(trackId) {
      // console.log('---setActiveTrack', trackId);
      await this.loadTrack(trackId);
      this.activeTrackId = trackId;
    },
    async archivePromo(promo) {
      console.log(`/promo/${promo.team.subdomain}/${promo.slug}/archive`)
      return api.post(`/promo/${promo.team.subdomain}/${promo.slug}/archive`);
    },
    async unArchivePromo(promo) {
      console.log(`/promo/${promo.team.subdomain}/${promo.slug}/unarchive`)
      return api.post(`/promo/${promo.team.subdomain}/${promo.slug}/unarchive`);
    },
    async resetBookHistory(promo) {
      const tracks = this.sortedTracksByPlaylistId[promo.id];
      let newHistory = [];
      for (let track of tracks) {
        let postData = {
          team_id: promo.team_id,
          book_id: promo.book_id,
          promo_id: promo.id,
          track_id: track.id,
          last_played: 0,
        };
        newHistory.push(postData);
        this.currentTimeByTrackId[track.id] = 0;
        this.hasEndedByTrackId[track.id] = false;
      }
      const { data } = await api.post(`/played/batch`, newHistory);
      let lastPlayed = await this.fetchLastPlayedByPromo(promo.id, true);
      for (let row of lastPlayed) {
        this.currentTimeByTrackId[row.track_id] = row.last_played;
      }
    },
    async cancelDownloadingPlaylist(playlistId) {
      let playlist = this.playlistsById[playlistId];
      for (let track of playlist.tracks) {
        await Playback.cancelTrackDownload({
          trackId: track.id
        });
        delete this.downloadProgressByTrackId[track.id];
      }
    },
    async cancelDownloadingTrack(trackId) {
      await Playback.cancelTrackDownload({
        trackId: trackId
      });
      delete this.downloadProgressByTrackId[trackId];
    },
    async downloadPlaylist(playlistId, removePlaylist = false) {
      const tracks = this.sortedTracksByPlaylistId[playlistId];
      if (isPlatform('capacitor') && !removePlaylist && !this.isSufficientSpaceForPlaylist(playlistId)) {
        alert(`Insufficient space. Book requires ${this.totalBytesForPlaylist(playlistId)}MB.`);
        return false;
      }

      if (isPlatform('capacitor')) {
        let hasSeenDownloadAlert = (await Preferences.get({ key: 'hasSeenDownloadAlert' }))?.value;
        if (hasSeenDownloadAlert !== 'true') {
          alert('Please keep the app open and screen awake while downloading books until download finishes.');
          await Preferences.set({
            key: 'hasSeenDownloadAlert',
            value: "true",
          });
        }
      }

      if (!removePlaylist) {
        this.playlistDownloadRequestById[playlistId] = true;
      }
      await this.fetchLastPlayedByPromo(playlistId, true);
      const promises = tracks.map(
        async (track) => await this.downloadTrack(track.id, removePlaylist),
      );
      return await Promise.allSettled(promises);
    },

    async isSufficientSpaceForPlaylist(playlistId) {
      const totalSizeBytes = this.totalBytesForPlaylist(playlistId);
      const info = await Device.getInfo();
      return info.realDiskFree > totalSizeBytes;
    },

    async isSufficientSpaceForTrack(trackId) {
      const track = this.tracksById[trackId];
      const info = await Device.getInfo();
      return info.realDiskFree > (track.filesize / (1024 * 1024));
    },

    totalMegaBytesForPlaylist(playlistId) {
      return this.totalBytesForPlaylist(playlistId) / (1024 * 1024);
    },

    totalBytesForPlaylist(playlistId) {
      const tracks = this.sortedTracksByPlaylistId[playlistId];
      let totalFileSizeBytes = 0;
      for (let track of tracks) {
        totalFileSizeBytes += track.filesize;
      }
      return totalFileSizeBytes;
    },

    async removeTrack(trackId) {
      return (await this.downloadTrack(trackId, true));
    },

    async downloadTrack(trackId, removeTrack = false) {
      const track = this.tracksById[trackId];
      const playlistId = this.playlistIdByTrackId[trackId];
      const playlist = this.playlistsById[playlistId];
      const path = `${playlist.slug}/${track.id}.${track.ext}`;
      if (isPlatform('capacitor')) {
        if (removeTrack) {
          // alert('deleting', track.id)
          let res = await Playback.deleteTrack({
            id: track.id,
            extension: track.ext
          });

          const sqlite = window.sqlite;
          const isConn = (await sqlite.isConnection("db", false)).result;
          let db;
          const ret = await sqlite.checkConnectionsConsistency();
          if (ret.result && isConn) {
            db = await sqlite.retrieveConnection("db", false);
          } else {
            db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
          }

          await db.run(`DELETE FROM downloaded_files where book_id = ? AND track_id = ?`, [
            playlist.book.id,
            track.id
          ]);

          try {
            this.downloadProgressByTrackId[trackId] = null;
            this.setTrackPath(trackId, null);
            this.setTrackAudioUrl(trackId, track.track);
            // localStorage.removeItem(path);
            return true;
          } catch (err) {
            console.error(err.message, err);
            return false;
          }
          // alert(JSON.stringify(res))
          return;
        } else {
          let sufficientSpace = await this.isSufficientSpaceForTrack(trackId);
          if (!sufficientSpace) {
            alert(`Insufficient space. Chapter requires ${(track.filesize / (1024 * 1024))}MB.`);
            return false;
          }
          let downloadedTrack = await Playback.downloadTrack({
            path,
            track: track.track,
            id: track.id,
            index: track.index,
            audioUrl: track.track,
            title: `${track.name}${track.title ? ' - ' + track.title : ''}`,
            artist: playlist?.book?.author_list,
            albumTitle: playlist?.book?.title,
            coverUrl: `${playlist?.book?.cover}?w=512&h=512&fm=png`,
            extension: track.ext
          });

          const sqlite = window.sqlite;
          const isConn = (await sqlite.isConnection("db", false)).result;
          let db;
          const ret = await sqlite.checkConnectionsConsistency();
          if (ret.result && isConn) {
            db = await sqlite.retrieveConnection("db", false);
          } else {
            db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
          }

          await db.run(`INSERT INTO downloaded_files(book_id, track_id) VALUES(?,?)`, [
            playlist.book.id,
            track.id
          ]);

          return downloadedTrack;
        }
      } else {
        if (removeTrack) {
          try {
            const fsResponse = await Filesystem.deleteFile({
              path,
              directory: Directory.Library,
            });
            this.downloadProgressByTrackId[trackId] = null;
            this.setTrackPath(trackId, null);
            this.setTrackAudioUrl(trackId, track.track);
            localStorage.removeItem(path);
            return true;
          } catch (err) {
            console.error(err.message, err);
            return false;
          }
        } else {
          try {
            const stat = await Filesystem.stat({
              path,
              directory: Directory.Library,
            });
            this.downloadProgressByTrackId[trackId] = 100;
            this.setTrackPath(trackId, path);
            return true;
          } catch (err) {
            console.log('Track not downloaded. Downloading track id:', trackId);
          }
          return await Playback.downloadTrack({
            path,
            track: track.track,
            id: track.id,
            index: track.index,
            audioUrl: track.track,
            title: `${track.name}${track.title ? ' - ' + track.title : ''}`,
            artist: playlist?.book?.author_list,
            albumTitle: playlist?.book?.title,
            coverUrl: `${playlist?.book?.cover}?w=512&h=512&fm=png`,
          });
        }
      }

    },

    async fetchLastPlayedByTeam(teamId) {
      this.isFetchingLastPlayed = true;
      const { data } = await api.get(`/played/team/${teamId}`);
      this.isFetchingLastPlayed = false;
      this.hasFetchedLastPlayed = !!data?.last_played;
      return data?.last_played || null;
    },

    async fetchLastPlayedByBook(bookId) {
      this.isFetchingLastPlayed = true;
      const { data } = await api.get(`/played/book/${bookId}`);
      this.isFetchingLastPlayed = false;
      this.hasFetchedLastPlayed = !!data?.last_played;
      return data?.last_played || null;
    },

    async fetchLastPlayedByPromo(promoId, doSync = false) {
      this.isFetchingLastPlayed = true;
      let networkStatus = await Network.getStatus();
      let data = {};
      if (networkStatus.connected) {
        try {
          data = (await api.get(`/played/promo/${promoId}`, {
            timeout: 10000
          })).data;
        } catch (e) {
        }
      }
      if (isPlatform('capacitor') && networkStatus.connected) {
        await Playback.callableSync();
      }
      if (isPlatform('capacitor') && networkStatus.connected && doSync) {
        await Playback.cacheServerHistory({
          promoId: promoId,
          team_slug: this.playlistsById[promoId].team.subdomain,
          promo_slug: this.playlistsById[promoId].slug
        });
      }
      if (isPlatform('capacitor')) {
        const sqlite = window.sqlite;
        const isConn = (await sqlite.isConnection("db", false)).result;
        let db;
        const ret = await sqlite.checkConnectionsConsistency();
        if (ret.result && isConn) {
          db = await sqlite.retrieveConnection("db", false);
        } else {
          db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
        }

        let historyRows = (await db.query(`SELECT track_id, team_id, book_id,
        playback_rate, current_position, seconds_played_since_sync,dt_last_updated,
        promo_id
        FROM all_playback_history WHERE promo_id = ${promoId} ORDER BY dt_last_updated DESC`)).values;
        let last_played = [];
        for (let row of historyRows) {
          last_played.push({
            team_id: row.team_id,
            book_id: row.book_id,
            promo_id: row.promo_id,
            track_id: row.track_id,
            seconds_played: row.seconds_played_since_sync,
            last_played: row.current_position,
            player_config: {
              playbackRate: row.playback_rate
            },
            updated_at: new Date(row.dt_last_updated * 1000)
          });
        }
        data.last_played = last_played;
      }

      this.isFetchingLastPlayed = false;
      this.lastPlayedById[promoId] = data?.last_played || null;
      this.hasFetchedLastPlayed = !!this.lastPlayedById[promoId];
      return this.lastPlayedById[promoId];
    },

    async fetchLastPlayedByUser() {
      this.isFetchingLastPlayed = true;
      try {
        const { data } = await api.get(`/played`);
        this.isFetchingLastPlayed = false;
        this.hasFetchedLastPlayed = !!data?.last_played;
      } catch (e) {
      }
      return data?.last_played || null;
    },

    async onTrackReady(track) {
      this.setLastPlayed(track.id);
    },

    async getLastPlayedOnDevice() {
      // alert('get last played')
      const sqlite = window.sqlite;
      const isConn = (await sqlite.isConnection("db", false)).result;
      let db;
      const ret = await sqlite.checkConnectionsConsistency();
      if (ret.result && isConn) {
        db = await sqlite.retrieveConnection("db", false);
      } else {
        db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
      }

      let lastPlayedRow = (await db.query(`SELECT track_id, team_id, book_id,
      playback_rate, current_position, seconds_played_since_sync,dt_last_updated,
      promo_id, team_slug, promo_slug 
      FROM all_playback_history ORDER BY dt_last_updated DESC LIMIT 1 `)).values?.[0];

      if (lastPlayedRow) {
        //alert(lastPlayedRow.track_id)
        let lastPlayed = await this.fetchLastPlayedByPromo(lastPlayedRow.promo_id);
        this.lastPlayedById[lastPlayedRow.promo_id] = lastPlayed;
        for (let row of lastPlayed) {
          this.currentTimeByTrackId[row.track_id] = row.last_played;
        }
        // this.preparePlaylist()
        // this.setLastPlayed(lastPlayedRow.track_id)
        //alert(JSON.stringify({trackId: lastPlayedRow.track_id, teamSlug: lastPlayedRow.team_slug, promoSlug: lastPlayedRow.promo_slug }))
        return { trackId: lastPlayedRow.track_id, teamSlug: lastPlayedRow.team_slug, promoSlug: lastPlayedRow.promo_slug };
      }
    },

    async setLastPlayed(trackId) {
      const track = this.tracksById[trackId];
      let lastPlayed = this.lastPlayedById[track.playlistId];
      // console.log('---setLastPlayed', trackId, lastPlayed);

      if (!lastPlayed) {
        this.queueForLastPlayed.push(trackId);
        return;
      }

      // this.currentTimeByTrackId[trackId] = lastPlayed.last_played.find(o=>o.track_id===trackId)

      if (
        lastPlayed?.promo_id &&
        this.activeTrackIsPlaying &&
        lastPlayed?.promo_id !== track.playlistId
      ) {
        await this.pause();
      }

      // Web can handle this during playback, 
      // but ios cannot because seeking will change the active track and mess up playback
      const shouldSetLastPlayed = !isPlatform('capacitor') || (isPlatform('capacitor') && !this.activeTrackIsPlaying);

      if (shouldSetLastPlayed) {
        lastPlayed.forEach(async (track) => {
          if (track.track_id === trackId) {
            if (track.player_config?.playbackRate !== undefined) this.rateByTrackId[trackId] = track.player_config?.playbackRate;
            const trackDuration = this.durationByTrackId[trackId];
            if (trackDuration) {
              const isAtEnd = track.last_played >= trackDuration - 1;
              if (isAtEnd) this.hasEndedByTrackId[trackId] = true;
              const currentTime = isAtEnd ? trackDuration : track.last_played;
              const currentPosition = (currentTime / trackDuration) * 100;
              // Only seek if the track is not playing
              if (currentPosition && this.statusByTrackId[trackId] !== 'playing') {
                await this.seekTo(currentPosition, trackId, false);
              }
            }
          }
        });
      }

      // if (
      //   lastPlayed?.promo_id !== track.playlistId &&
      //   this.lastPlayedById[track.playlistId]?.length
      // ) {
      //   lastPlayed = this.lastPlayedById[track.playlistId][0];
      // }
      // if (lastPlayed?.track_id === trackId) {
      //   await this.setActiveTrack(trackId);
      //   const rate = lastPlayed.player_config?.playbackRate || 1;
      //   // if (this.currentTimeByTrackId[trackId] < 1) {
      //   await this.scrub(lastPlayed.last_played, trackId);
      //   // }
      // }
    },

    async runLastPlayedQueue() {
      let promises = this.queueForLastPlayed.map(
        async (trackId) => await this.setLastPlayed(trackId),
      );
      await Promise.allSettled(promises);
      this.queueForLastPlayed = [];
    },

    async syncHistoryToServerWebApp(){
      const sqlite = window.sqlite;
      const isConn = (await sqlite.isConnection("db", false)).result;
      let db;
      const ret = await sqlite.checkConnectionsConsistency();
      if (ret.result && isConn) {
        db = await sqlite.retrieveConnection("db", false);
      } else {
        db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
      }
      
    },

    async storeLastPlayed(trackId = null, lastPlayed = null, playbackRate = null) {
      if (trackId === null) {
        trackId = this.activeTrackId || this.lastPlayedTrackId;
        if (!trackId) return;
      }
      if (lastPlayed === null) lastPlayed = this.currentTimeByTrackId[trackId];
      if (playbackRate === null) playbackRate = this.rateByTrackId[trackId] || 1;

      const track = this.tracksById[trackId];
      const promo = this.playlistsById[track.playlistId];
      let postData = {
        team_id: promo.team_id,
        book_id: promo.book_id,
        promo_id: promo.id,
        last_played: lastPlayed,
        player_config: {
          playbackRate,
        },
      };
      if (this.durationByTrackId[trackId] && this.durationByTrackId[trackId] !== 1) {
        postData.duration = this.durationByTrackId[trackId];
      }
      //const { data } = await api.post(`/played/${trackId}`, postData);
      //return data;
    },

    async storeSecondsPlayed(trackId = null, secondsPlayed) {
      if (trackId === null) {
        trackId = this.activeTrackId || this.lastPlayedTrackId;
        if (!trackId) return;
      }
      const track = this.tracksById[trackId];
      const promo = this.playlistsById[track.playlistId];
      // const { data } = await api.post(`/played/${trackId}`, {
      //   team_id: promo.team_id,
      //   book_id: promo.book_id,
      //   promo_id: promo.id,
      //   seconds_played: secondsPlayed,
      // });
      // return data;
    },

    getLoadingProgress({ trackId = null, playlistId = null }) {
      if (trackId) return this.bufferedByTrackId[trackId] || 0;
      if (playlistId) console.log('TODO: getLoadingProgress()', playlistId);
      return 0;
    },

    /**
     * Make sure we're not using the default value for track duration: 1.0
     */
    async getDurations() {
      Object.keys(this.durationByTrackId).forEach(async (trackId) => {
        let duration = this.durationByTrackId[trackId];
        if (duration == 1) {
          await this.checkTrackProgress(trackId);
        }
      });
    },

    async updateProgress(progress = fallbackProgress) {
      // console.log('---updateProgress', progress);
      if (progress?.currentTime !== undefined) {
        let lastCurrentTime = this.currentTimeByTrackId[progress.trackId];
        // Only if currentTime changes
        if (progress.currentTime != lastCurrentTime) {
          this.currentTimeByTrackId[progress.trackId] = progress.currentTime;
          // If nothing changes for 2 seconds while "playing", then showLoading = true
          clearTimeout(showLoadingTimeout);
          this.showLoading = false;
          showLoadingTimeout = setTimeout(() => {
            if (this.activeTrackIsPlaying) {
              this.showLoading = true;
            }
          }, 2000);
        }
      }
      if (progress?.duration !== undefined) {
      }
      if (progress?.buffered !== undefined)
        this.bufferedByTrackId[progress.trackId] = progress.buffered;
      if (progress?.playbackRate !== undefined) {
        this.playbackRate = progress.playbackRate;
        this.rateByTrackId[progress.trackId] = progress.playbackRate;
      }
      if (progress?.status !== undefined) this.statusByTrackId[progress.trackId] = progress.status;
      if (progress?.hasEnded !== undefined) {
        const alreadyEnded = this.hasEndedByTrackId[progress.trackId];
        this.hasEndedByTrackId[progress.trackId] = progress.hasEnded;
        if (progress.hasEnded && !alreadyEnded && !progress?.cached) this.onTrackEnded(progress.trackId);
      }
      if (progress?.isReady !== undefined) {
        this.setTrackIsReady(progress.trackId, progress.isReady);
      }
      if (progress?.downloadProgress !== undefined) {
        if (parseInt(progress.downloadProgress) === -2) {
          //alert(`delete id:${progress.trackId} prog ${progress.downloadProgress}`)
          delete this.downloadProgressByTrackId[progress.trackId];
          const playlistId = this.playlistIdByTrackId[progress.trackId];
          let evt = new CustomEvent('playlistDownloadFailed', { detail: playlistId });
          window.dispatchEvent(evt);

        } else {
          this.downloadProgressByTrackId[progress.trackId] = progress.downloadProgress;
        }

      }

      //clear old played tracks if JS turned off on iphone
      if (progress?.status === 'playing') {
        this.activeTrackId = progress?.trackId;
        let updatedStatuses = [];
        updatedStatuses[progress?.trackId] = 'playing';
        for (let [id, trackStatus] of Object.entries(this.statusByTrackId)) {
          if (this.activeTrackId !== parseInt(id)) {
            updatedStatuses[parseInt(id)] = 'paused';
          }
        }
        this.statusByTrackId = updatedStatuses;
      }

      return progress;
    },

    getProgress(trackId) {
      const track = this.tracksById[trackId];
      const isReady = isRef(track?.isReady) ? !!track?.isReady?.value : !!track?.isReady;

      return {
        currentTime: this.currentTimeByTrackId[trackId] || 0,
        duration: this.durationByTrackId[trackId] || 1,
        buffered: this.bufferedByTrackId[trackId] || 0,
        rate: this.rateByTrackId[trackId] || 1,
        status: this.statusByTrackId[trackId],
        hasEnded: this.hasEndedByTrackId[trackId] || false,
        isReady,
      };
    },

    /*
    async augmentPlaylist(playlist) {
      const playlistTracks = playlist.tracks || [];
      const tempArr = playlistTracks.map((track) => ({
        ...track,
        playlistId: playlist.id,
      }));
      const tracks = ref(tempArr);
      const isPreparing = ref(true);
      const trackPromises = playlistTracks.map((track, index) =>
        this.augmentTrack(track, index, playlist),
      );

      trackPromises.forEach((promise, index) => {
        promise.then((resolvedTrack) => {
          tracks.value[index] = resolvedTrack;
        });
      });

      Promise.all(trackPromises).then(() => {
        isPreparing.value = false;
      });

      return {
        ...playlist,
        tracks,
        isPreparing,
        isLoading: computed(() =>
          tracks.value.some(
            (track) =>
              !this.statusByTrackId[track.id] || this.statusByTrackId[track.id] === 'loading',
          ),
        ),
        isPlayable: computed(() => tracks.value.some((track) => track.isPlayable)),
        isDownloadable: playlist?.allow_downloads && playlist.permissions?.canDownload,
      };
    },

    async augmentTrack(track, index, playlist) {
      return AudioPlugin.prepareTrack({
        id: track.id,
        index,
        audioUrl: track.track,
        title: `${track.name}${track.title ? ' - ' + track.title : ''}`,
        artist: playlist?.book?.author_list,
        albumTitle: playlist?.book?.title,
        coverUrl: `${playlist?.book?.cover}?w=512&h=512&fm=png`,
      }).then((preparedTrack) => {
        this.updateProgress(preparedTrack.progress);
        if (track.duration) this.durationByTrackId[track.id] = track.duration;

        return {
          ...track,
          playlistId: playlist.id,
          isPlayable: preparedTrack.isPlayable,
          audioTrack: preparedTrack.track,
        };
      });
    },
*/

    async beforeStoreReset() {
      this.sleepTimeout = null;
      this.trackEndTimeout = null;
      this.sleepUntilTrackEnd = false;
      this.activeTrackId = null;
      await this.pauseAllAudio();
      await Playback.removeQueue();
    },
  },
});

const fallbackProgress = {
  trackId: null,
  currentTime: 0,
  duration: 1,
  buffered: 0,
  playbackRate: 1,
  hasEnded: false,
  status: undefined,
};
