import { ref } from 'vue';
import { isPlatform } from '@ionic/vue';
import { Capacitor } from "@capacitor/core";
import { CapacitorSQLite, SQLiteConnection } from "@capacitor-community/sqlite";
import {
	defineCustomElements as jeepSqlite,
	applyPolyfills,
} from "jeep-sqlite/loader";

const platform = Capacitor.getPlatform();
const sqlite = new SQLiteConnection(CapacitorSQLite);

const createLibraryApiCache = `
	CREATE TABLE IF NOT EXISTS lib_api_cache (
    url TEXT NOT NULL,
    content TEXT NOT NULL
	);`;

const createHistoryApiCache = `
	CREATE TABLE IF NOT EXISTS history_api_cache (
		user_id INTEGER NOT NULL,
		track_id INTEGER NOT NULL,
		content TEXT NOT NULL
	);`;

const createPromosApiCache = `
	CREATE TABLE IF NOT EXISTS promos_api_cache (
		promo_url TEXT NOT NULL,
		content TEXT NOT NULL
	);`;

const createDownloadedPromosCache = `
  CREATE TABLE IF NOT EXISTS downloaded_promos_cache (
    user_id INTEGER NOT NULL,
    promo_id INTEGER NOT NULL,
    content TEXT NOT NULL
	);`;

const createDownloadedFiles = `
  CREATE TABLE IF NOT EXISTS downloaded_files (
    book_id INTEGER NOT NULL,
    track_id INTEGER NOT NULL
	);`;

const create_sync_playback_history = `
	CREATE TABLE "sync_playback_history" (
		"track_id"    INTEGER,
		"team_id"    INTEGER,
		"book_id"    INTEGER,
		"playback_rate"    REAL,
		"current_position"    REAL,
		"seconds_played_since_sync"    REAL,
		"dt_last_updated"    INTEGER,
		"promo_id"    INTEGER,
		"team_slug" TEXT,
		"promo_slug" TEXT,
		PRIMARY KEY("track_id")
	);`;

const create_all_playback_history = `
  CREATE TABLE "all_playback_history" (
    "track_id"    INTEGER,
    "team_id"    INTEGER,
    "book_id"    INTEGER,
    "playback_rate"    REAL,
    "current_position"    REAL,
    "seconds_played_since_sync"    REAL,
    "dt_last_updated"    INTEGER,
    "promo_id"    INTEGER,
    "team_slug" TEXT,
    "promo_slug" TEXT,
    PRIMARY KEY("track_id")
  );`;

async function isConnected() {
	const con = await sqlite.isConnection("db", false);
	const ret = await sqlite.checkConnectionsConsistency();
	return ret.result && con.result;
}

async function getDB() {
	let db;
	const connected = await isConnected();
	if (connected) {
		db = await sqlite.retrieveConnection("db", false);
	} else {
		db = await sqlite.createConnection("db", false, "no-encryption", 1, false);
	}
	return db;
}

async function openDB() {
	const db = await getDB();
	await db.open();
	return db;
}

async function closeDB() {
	const db = await getDB();
	await db.close();
}

export async function queryDB(sql, params = []) {
	const db = await getDB();
	const ret = await db.query(sql, params);
	return ret?.values || [];
}

export async function runDB(sql, params = []) {
	try {
		const db = await getDB();
		await db.run(sql, params);
		return true;
	} catch (e) {
		console.log(e);
	}
	return false;
}

export async function initDB() {
	await applyPolyfills();
	jeepSqlite(window);

	if (platform === "web") {
		// Create the 'jeep-sqlite' Stencil component
		const $jeepSqlite = document.createElement("jeep-sqlite");
		document.body.appendChild($jeepSqlite);
		await customElements.whenDefined("jeep-sqlite");
		// Initialize the Web store
		await sqlite.initWebStore();
	}

	const db = await getDB();
	return await Promise.all([
		db.execute(createLibraryApiCache),
		db.execute(createHistoryApiCache),
		db.execute(createPromosApiCache),
		db.execute(createDownloadedPromosCache),
		db.execute(createDownloadedFiles),
		db.execute(create_all_playback_history),
		db.execute(create_sync_playback_history)
	]).catch(e => {
		console.error(e);
	});
}

export default sqlite;
